
function Pergjegjesia() {

    return (
        <>
            <div className='pageContainer'>
                <header>
                    <p className='pageHeader'>Heqja e Përgjegjësisë</p>
                </header>
                <p className="distop"></p>
                <p className="dis">Ky mohim për heqjen e përgjegjësisë ka termat për refuzimin e çdo lloj përgjegjësie.
                    Të dhënat e ngarkuara nga përdoruesit në platformën tonë paraqesin të dhënat e përdoruesve individualë dhe komercial, dhe jo ato të Shpiza.com ose ndonjë prej stafit të saj. Platforma jonë shërben vetëm si një shërbim për përdoruesit. Ne nuk jemi përgjegjës për përmbajtjen e ngarkuar nga përdoruesit. Ndërsa ne bëjmë përpjekje të arsyeshme për të monitoruar dhe/ose moderuar përmbajtjen e postuar në platformën tonë. Përdorimi i platformës sonë është përgjegjësi e juaja. Ju mbani përgjegjësi të plotë dhe rrezik të humbjes që vjen nga përdorimi juaj i informacionit, përmbajtjes ose materialeve tjera. Ne heqim përgjegjësinë tone për çdo virus të sistemeve kompjuterike ose pajisjeve të tjera të përdorura për të hyrë në platformën tonë, dhe shprehimisht refuzojmë përgjegjësinë për veprimet e ndërmarra ose jo të marra bazuar në ndonjë ose të gjitha përmbajtjet e platformës sonë. Ne nuk kemi domosdoshmërisht aftësinë për të kontrolluar informacionin ose përmbajtjen e publikuar në platformën tonë. Ne nuk pranojmë asnjë përgjegjësi për dëmet ose përgjegjësinë që rrjedh nga publikimi i juaj i informacionit ose përmbajtjes, as proceset e heqjes ose ankesave që rrjedhin nga një informacion ose përmbajtja e tillë. Heqja e një informacioni ose përmbajtjes së tillë dhe/ose pjesëmarrjes në proceset e ankesave është përgjegjësia juaj e vetme.
                </p>



            </div>
        </>
    )
}

export default Pergjegjesia