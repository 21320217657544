// components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';

const PrivateRoute = ({ element, allowedUserType }) => {
  const { userType, loading } = useUser();

  if (loading) {
    return <p>Loading...</p>;
  }

  return userType === allowedUserType ? element : <Navigate to="/kyqu" replace />;
};

export default PrivateRoute;