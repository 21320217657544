import React from 'react';

const PaymentSuccess = () => {
  return (
    <div>
      <h1>Pagesa është kryer</h1>
      <p>Faleminderit për pagesën tuaj. Transaksioni juaj ishte i suksesshëm.</p>
    </div>
  );
};

export default PaymentSuccess;