import { useState, useEffect, useRef } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from 'firebase/storage'
import { doc, updateDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase.config'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import Spinner from '../components/Spinner'
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

function EditJobProfile() {
    // eslint-disable-next-line
    const [dataeskadimit, setDataeskadimit] = useState('');
    const [loading, setLoading] = useState(false)
    const [listing, setListing] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        lastname: '',
        birthdate: '',
        profesioni: '',
        pervoja: '',
        edukimi: '',
        cerfifikimet: '',
        aftesite: '',
        bio: '',
        jobseekerimage: '',
        premiumjobseeker: false,
    })

    const {
        name,
        lastname,
        birthdate,
        profesioni,
        pervoja,
        edukimi,
        certifikimet,
        aftesite,
        premiumjobseeker,
        bio,
        jobseekerimage,
    } = formData

    const [category, setCategory] = useState(null);
    const [selectedVendbanimi, setSelectedVendbanimi] = useState(null);


    const kualifikimi = [
        { value: 'Energji', label: 'Energji' },
        { value: 'Tregti', label: 'Tregti' },
        { value: 'Sherbime', label: 'Shërbime' },
        { value: 'Administrate', label: 'Administrate' },
        { value: 'Telekomunikime', label: 'Telekomunikime' },
        { value: 'Teknologji', label: 'Teknologji' },
        { value: 'Media', label: 'Media' },
        { value: 'Financiare', label: 'Financiare' },
        { value: 'Patundeshmeri', label: 'Patundshmëri' },
        { value: 'Transport', label: 'Transport' },
        { value: 'Ushqimore', label: 'Ushqimore' },
        { value: 'Mjekesi', label: 'Mjekësi' },
        { value: 'Tekstil', label: 'Tekstil' },
        { value: 'Hoteleri', label: 'Hoteleri' },
        { value: 'Edukim', label: 'Edukim' },
    ];


    const vendbanimi = [
        { value: 'Prishtine', label: 'Prishtine' },
        { value: 'Prizren', label: 'Prizren' },
        { value: 'Peje', label: 'Peje' },
        { value: 'Ferizaj', label: 'Ferizaj' },
        { value: 'Gjilan', label: 'Gjilan' },
        { value: 'Mitrovice', label: 'Mitrovice' },
        { value: 'Fushe Kosove', label: 'Fushe Kosove' },
        { value: 'Gjakove', label: 'Gjakove' },
        { value: 'Decan', label: 'Decan' },
        { value: 'Lipjan', label: 'Lipjan' },
        { value: 'Podujeve', label: 'Podujeve' },
        { value: 'Kline', label: 'Kline' },
        { value: 'Suhareke', label: 'Suhareke' },
        { value: 'Drenas', label: 'Drenas' },
        { value: 'Vushtri', label: 'Vushtri' },
        { value: 'Skenderaj', label: 'Skenderaj' },
        { value: 'Rahovec', label: 'Rahovec' },
        { value: 'Shtime', label: 'Shtime' },
        { value: 'Viti', label: 'Viti' },
        { value: 'Kacanik', label: 'Kacanik' },
        { value: 'Kastriot', label: 'Kastriot' },
    ];




    const auth = getAuth()
    const navigate = useNavigate()


    const handleEdukimiChange = (option) => {
        setCategory(option?.value);
        setFormData((prevState) => ({
            ...prevState,
            kualifikimi: option?.value, // Update the industria value in the form data
        }));
    };


    const handleBirthDateChange = (date) => {
        setDataeskadimit(date);
    };

    const params = useParams()
    const isMounted = useRef(true)



    const handleImageChange = (e) => {
        const fileList = e.target.files;
        // const imageArray = [];

        // // Convert FileList to Array
        // for (let i = 0; i < fileList.length; i++) {
        //   imageArray.push(fileList[i]);
        // }

        // Update the images state
        setFormData((prevState) => ({
            ...prevState,
            jobseekerimage: fileList,
        }));
    };

    const handleVendbanimiChange = (option) => {
        setCategory(option?.value);
        setFormData((prevState) => ({
            ...prevState,
            vendndodhja: option?.value, // Update the industria value in the form data
        }));
    };

    // Redirect if listing is not user's
    useEffect(() => {
        if (listing && listing.userRef !== auth.currentUser.uid) {
            toast.error('You can not edit that listing')
            navigate('/')
        }
    })

    // Fetch listing to edit
    useEffect(() => {
        setLoading(true)
        const fetchListing = async () => {
            const docRef = doc(db, 'listings', params.listingId)
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                setListing(docSnap.data())
                setFormData({ ...docSnap.data() })
                setLoading(false)
            } else {
                navigate('/')
                toast.error('Listing does not exist')
            }
        }

        fetchListing()
    }, [params.listingId, navigate])

    // Sets userRef to logged in user
    useEffect(() => {
        if (isMounted) {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    setFormData({ ...formData, userRef: user.uid })
                } else {
                    navigate('/sign-in')
                }
            })
        }

        return () => {
            isMounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted])

    const onSubmit = async (e) => {
        e.preventDefault()

        setLoading(true)


        if (jobseekerimage.length > 6) {
            setLoading(false)
            toast.error('Max 1 image')
            return
        }



        // Store image in firebase
        const storeImage = async (image) => {
            return new Promise((resolve, reject) => {
                const storage = getStorage()
                const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`

                const storageRef = ref(storage, 'jobseekerimage/' + fileName)

                const uploadTask = uploadBytesResumable(storageRef, image)

                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        console.log('Upload is ' + progress + '% done')
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused')
                                break
                            case 'running':
                                console.log('Upload is running')
                                break
                            default:
                                break
                        }
                    },
                    (error) => {
                        reject(error)
                    },
                    () => {
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            resolve(downloadURL)
                        })
                    }
                )
            })
        }

        const imgUrls = await Promise.all(
            [...jobseekerimage].map((image) => storeImage(image))
        ).catch(() => {
            setLoading(false)
            toast.error('Images not uploaded')
            return
        })

        const formDataCopy = {
            ...formData,
            imgUrls,
            timestamp: serverTimestamp(),
        }

        delete formDataCopy.jobseekerimage


        // Update listing
        const docRef = doc(db, 'listings', params.listingId)
        await updateDoc(docRef, formDataCopy)
        setLoading(false)
        toast.success('Listing saved')
        navigate(`/category/${formDataCopy.type}/${docRef.id}`)
    }

    const onMutate = (e) => {
        let boolean = null

        if (e.target.value === 'true') {
            boolean = true
        }
        if (e.target.value === 'false') {
            boolean = false
        }

        // Files
        if (e.target.files) {
            setFormData((prevState) => ({
                ...prevState,
                jobseekerimage: e.target.files,
            }))
        }

        // Text/Booleans/Numbers
        if (!e.target.files) {
            setFormData((prevState) => ({
                ...prevState,
                [e.target.id]: boolean ?? e.target.value,
            }))
        }
    }


    if (loading) {
        return <Spinner />
    }

    return (
        <div className='profile'>
            <header>
                <p className='pageHeader'>Edito Listimin</p>
            </header>

            <main>
                <form onSubmit={onSubmit}>
                    <label className='formLabel'>Emri</label>
                    <input
                        className='formInputName'
                        type='text'
                        id='name'
                        value={name}
                        onChange={onMutate}
                        maxLength='36'
                        minLength='3'
                        required
                    />

                    <label className='formLabel'>Mbiemri</label>
                    <input
                        className='formInputName'
                        type='text'
                        id='lastname'
                        value={lastname}
                        onChange={onMutate}
                        maxLength='36'
                        minLength='3'
                        required
                    />

                    <div>
                        <label className='formLabel'>Vendbanimi</label>
                        <Select
                            className='formSelect'
                            options={vendbanimi}
                            onChange={handleVendbanimiChange}
                            id='vendbanimi'
                            placeholder="Zgjedhe Vendbaimin"
                            required
                        />
                    </div>

                    <label className='formLabel'>Edukimi</label>
                    <Select
                        className='formSelect'
                        options={kualifikimi}
                        onChange={handleEdukimiChange}
                        id='kualifikimi'
                        placeholder="Zgjedh Edukimin"
                        required
                    />

                    <label className='formLabel'>Profesioni</label>
                    <input
                        className='formInputName'
                        type='text'
                        id='profesioni'
                        value={profesioni}
                        onChange={onMutate}
                        maxLength='36'
                        minLength='2'
                        required
                    />

                    <div>
                        <label className='formLabel'>Data e Lindjes</label>
                        <DatePicker
                            className='formInputName'
                            selected={birthdate}
                            onChange={handleBirthDateChange}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Zgjedh daten e lindjes"
                            id='birthdate'
                            required
                        />
                    </div>

                    <label className='formLabel'>Përvoja</label>
                    <input
                        className='formInputName'
                        type='number'
                        id='pervoja'
                        value={pervoja}
                        onChange={onMutate}
                        maxLength='99'
                        minLength='1'
                        required
                    />

                    <label className='formLabel'>Certifikimet</label>
                    <textarea
                        className='formInputName'
                        type='text'
                        id='certifikimet'
                        value={certifikimet}
                        onChange={onMutate}
                        maxLength='3600'
                        minLength='10'
                        rows="15"
                        required
                    />

                    <label className='formLabel'>Aftësitë Tjera</label>
                    <textarea
                        className='formInputName'
                        type='text'
                        id='aftesite'
                        value={aftesite}
                        onChange={onMutate}
                        maxLength='3600'
                        minLength='10'
                        rows="15"
                        required
                    />

                    <label className='formLabel'>Prezentim i shkurtër i vetes</label>
                    <textarea
                        className='formInputName'
                        type='text'
                        id='bio'
                        value={bio}
                        onChange={onMutate}
                        maxLength='3600'
                        minLength='1'
                        rows="15"
                        required
                    />


                    {/* </div> */}

                    <label className='formLabel'>Fotot</label>
                    <p className='imagesInfo'>
                        Foto e pare do te vendoset si logo (max 1).
                    </p>
                    <input
                        className='formInputFile'
                        type='file'
                        id='jobseekerimage'
                        onChange={onMutate}
                        max='6'
                        accept='.jpg,.png,.jpeg'
                        multiple

                    />
                    <button type='submit' className='primaryButton createListingButton'>
                        Publiko Konkursin
                    </button>
                </form>
            </main>
        </div>
    )
}

export default EditJobProfile
