import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase.config';

function AdminPage() {
  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [isAdminUser, setIsAdminUser] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('User state changed:', user);
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        console.log('Current user is set:', currentUser);
        setLoading(true);
        const isAdminUser = await isAdmin(currentUser);
        setIsAdminUser(isAdminUser);
        if (isAdminUser) {
          await fetchUsers();
        }
        setLoading(false);
      } else {
        console.log('No current user.');
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  const fetchUsers = async () => {
    try {
      console.log('Fetching users...');
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log('Users fetched:', usersData);
      setUsers(usersData);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!currentUser) {
    console.log('Loading current user...');
    return <p>Loading...</p>;
  }

  if (!isAdminUser) {
    console.log('Access denied for user:', currentUser);
    return <p>Access denied. You must be an admin to view this page.</p>;
  }

  return (
    <div className="dashboard-container">
      <h1 className="dashboard-header">Admin Dashboard</h1>
      <form className="search-user-form">
        <input
          type="text"
          className="search-input"
          placeholder="Kërko Përdoruesit..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </form>
      <h3>Lista e Përdoruesve</h3>
      <ul className="user-list">
        {filteredUsers.map((user) => (
          <li className="user-item" key={user.id}>
            <Link to={`/user-listings/${user.id}`} className="user-link">
              <p className="user-name">{user.name}</p>
              <p className="user-email">{user.email}</p>
            </Link>
          </li>
        ))}
      </ul>
      <Link to="/" className="back-link">Back to Home</Link>
    </div>
  );
}

const isAdmin = async (user) => {
  try {
    const userDocRef = doc(db, 'users', user.uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      return userData.isAdmin === true;
    } else {
      console.error('User document not found for isAdmin check.');
      return false;
    }
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};

export default AdminPage;