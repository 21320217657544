import { useNavigate, useLocation } from 'react-router-dom'
import { ReactComponent as ContactIcon } from '../assets/svg/contactIcon.svg'
import { ReactComponent as PersonOutlineIcon } from '../assets/svg/personOutlineIcon.svg'
import { ReactComponent as SearchIcon } from '../assets/svg/searchIcon.svg'

function Navbar() {
  const navigate = useNavigate()
  const location = useLocation()

  const pathMatchRoute = (route) => {
    if (route === location.pathname) {
      return true
    }
  }

  return (
    <footer className='navbar'>
      <nav className='navbarNav'>
        <ul className='navbarListItems'>
          <li className='navbarListItem' onClick={() => navigate('/kerko-pune')}>
            <SearchIcon
              fill={pathMatchRoute('/kerko-pune') ? '#ff8a2a' : '#ededed'}
              width='36px'
              height='36px'
            />
            <p
              className={
                pathMatchRoute('/kerko-pune')
                  ? 'navbarListItemNameActive'
                  : 'navbarListItemName'
              }
            >
              Kërko Konkurse
            </p>
          </li>


          <li className='navbarListItem' onClick={() => navigate('/profili')}>
            <PersonOutlineIcon
              stroke={pathMatchRoute('/kyqu') ? '#ff8a2a' : '#ededed'}
              width='36px'
              height='36px'
            />
            <p
              className={
                pathMatchRoute('/kyqu')
                  ? 'navbarListItemNameActive'
                  : 'navbarListItemName'
              }
            >
              Posto Konkurse
            </p>
          </li>

          <li className='navbarListItem' onClick={() => navigate('/kontakti')}>
            <ContactIcon
              fill={pathMatchRoute('/kontakti') ? '#ff8a2a' : '#ededed'}
              width='36px'
              height='36px'
            />
            <p
              className={
                pathMatchRoute('/kontakti')
                  ? 'navbarListItemNameActive'
                  : 'navbarListItemName'
              }
            >
              Kontakti
            </p>
          </li>

        </ul>
      </nav>
    </footer>
  )
}

export default Navbar
