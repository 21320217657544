import React from 'react';

const PaymentCancel = () => {
  return (
    <div>
      <h1>Pagesa është anuluar</h1>
      <p>Pagesa juaj është anuluar. Mund të provoni përsëri më vonë.</p>
    </div>
  );
};

export default PaymentCancel;