import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';
import { setDoc, doc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase.config';
import { ReactComponent as ArrowRightIcon } from '../assets/svg/keyboardArrowRightIcon.svg';
import visibilityIcon from '../assets/svg/visibilityIcon.svg';

function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    userType: 'user', // Default user type
  });
  const { name, email, password, userType } = formData;
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Component re-rendered.');
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    console.log(`Input changed: ${id} = ${value}`);
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleRadioChange = (e) => {
    const { value } = e.target;
    console.log(`Radio button changed: userType = ${value}`);
    setFormData((prevState) => ({
      ...prevState,
      userType: value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;

      await updateProfile(auth.currentUser, {
        displayName: name,
      });

      const formDataCopy = { ...formData };
      delete formDataCopy.password;
      formDataCopy.timestamp = serverTimestamp();

      // Check user type and set appropriate collection
      const collection = userType === 'user' ? 'users' : 'jobseekers';
      await setDoc(doc(db, collection, user.uid), formDataCopy);

      navigate('/');
    } catch (error) {
      toast.error('Diçka shkoi gabim me regjistrimin');
      console.error('Error during sign up:', error.message);
    }
  };

  return (
    <>
      <div className='pageContainer'>
        <header>
          <p className='pageHeader'>Miresevini!</p>
        </header>

        <form onSubmit={onSubmit}>
          <input
            type='text'
            className='nameInput'
            placeholder='Emri'
            id='name'
            value={name}
            onChange={handleInputChange}
          />
          <input
            type='email'
            className='emailInput'
            placeholder='Shënoni një Email'
            id='email'
            value={email}
            onChange={handleInputChange}
          />

          <div className='passwordInputDiv'>
            <input
              type={showPassword ? 'text' : 'password'}
              className='passwordInput'
              placeholder='Krijoni një fjalëkalim'
              id='password'
              value={password}
              onChange={handleInputChange}
            />

            <img
              src={visibilityIcon}
              alt='show password'
              className='showPassword'
              onClick={() => setShowPassword((prevState) => !prevState)}
            />
          </div>

          <div className='radioContainer'>
            <div className='radioGroup'>
              <input
                type='radio'
                id='user'
                name='userType'
                value='user'
                checked={userType === 'user'}
                onChange={handleRadioChange}
                className='radioInput'
              />
              <label htmlFor='user' className={userType === 'user' ? 'active' : ''}>Punëdhënës</label>
            </div>

            {/* <div className='radioGroup'>
              <input
                type='radio'
                id='jobseeker'
                name='userType'
                value='jobseeker'
                checked={userType === 'jobseeker'}
                onChange={handleRadioChange}
                className='radioInput'
              />
              <label htmlFor='jobseeker' className={userType === 'jobseeker' ? 'active' : ''}>Punëkërkues</label>
            </div> */}
          </div>

          <Link to='/forgot-password' className='forgotPasswordLink'>
            Kam harruar fjalekalimin
          </Link>

          <div className='signUpBar'>
            <p className='signUpText'>Regjistrohu</p>
            <button className='signUpButton'>
              <ArrowRightIcon fill='#ffffff' width='34px' height='34px' />
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default SignUp;