import payseralo from '../assets/jpg/paysera-logo.png'
import '../css/paysera.css'

function Paysera() {

  return (

    <img
      src={payseralo}
      alt="payseralo"
      className='payseraimg'
    />
  )



}

export default Paysera