import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getAuth, updateProfile } from 'firebase/auth'
import {
    updateDoc,
    doc,
    collection,
    getDocs,
    query,
    where,
    orderBy,
    deleteDoc,
} from 'firebase/firestore'
import { db } from '../firebase.config'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ListingItem from '../components/ListingItem'
import arrowRight from '../assets/svg/keyboardArrowRightIcon.svg'
import homeIcon from '../assets/svg/homeIcon.svg'
import { startAfter, limit } from 'firebase/firestore';
import Spinner from '../components/Spinner';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import Logonor from '../components/Logonor'

// Tasks
// 1. Implement JobSearch Functionality within the Jobseeker Profile
// 2. 

function JobSeekerProfile() {
    const auth = getAuth()
    const [loading, setLoading] = useState(true)
    const [listings, setListings] = useState(null)
    const [changeDetails, setChangeDetails] = useState(false)
    // const [formData, setFormData] = useState({
    //     name: '',
    //     email: '',
    // })

    const [formData, setFormData] = useState({
        name: auth.currentUser.displayName,
        email: auth.currentUser.email,
    })

    const [lastFetchedListing, setLastFetchedListing] = useState(null);
    const [initialLoad, setInitialLoad] = useState(false);
    const [favorites, setFavorites] = useState([]); // State to store favorite listings
    const [premiumListings, setPremiumListings] = useState([]);
    const [category, setCategory] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [authLoading, setAuthLoading] = useState(true); // State to handle authentication loading


    const { name, email } = formData

    const categories = [
        { value: 'Energji', label: 'Energji' },
        { value: 'Tregti', label: 'Tregti' },
        { value: 'Sherbime', label: 'Shërbime' },
        { value: 'Administrate', label: 'Administrate' },
        { value: 'Telekomunikime', label: 'Telekomunikime' },
        { value: 'Teknologji', label: 'Teknologji' },
        { value: 'Media', label: 'Media' },
        { value: 'Financiare', label: 'Financiare' },
        { value: 'Patundeshmeri', label: 'Patundshmëri' },
        { value: 'Transport', label: 'Transport' },
        { value: 'Ushqimore', label: 'Ushqimore' },
        { value: 'Mjekesi', label: 'Mjekësi' },
        { value: 'Tekstil', label: 'Tekstil' },
        { value: 'Hoteleri', label: 'Hoteleri' },
        { value: 'Edukim', label: 'Edukim' },
    ];


    const city = [
        { value: 'Prishtine', label: 'Prishtine' },
        { value: 'Prizren', label: 'Prizren' },
        { value: 'Peje', label: 'Peje' },
        { value: 'Ferizaj', label: 'Ferizaj' },
        { value: 'Gjilan', label: 'Gjilan' },
        { value: 'Mitrovice', label: 'Mitrovice' },
        { value: 'Fushe Kosove', label: 'Fushe Kosove' },
        { value: 'Gjakove', label: 'Gjakove' },
        { value: 'Decan', label: 'Decan' },
        { value: 'Lipjan', label: 'Lipjan' },
        { value: 'Podujeve', label: 'Podujeve' },
        { value: 'Kline', label: 'Kline' },
        { value: 'Suhareke', label: 'Suhareke' },
        { value: 'Drenas', label: 'Drenas' },
        { value: 'Vushtri', label: 'Vushtri' },
        { value: 'Skenderaj', label: 'Skenderaj' },
        { value: 'Rahovec', label: 'Rahovec' },
        { value: 'Shtime', label: 'Shtime' },
        { value: 'Viti', label: 'Viti' },
        { value: 'Kacanik', label: 'Kacanik' },
        { value: 'Kastriot', label: 'Kastriot' },
    ];

    useEffect(() => {
        if (auth.currentUser) {
            setFormData({
                name: auth.currentUser.displayName,
                email: auth.currentUser.email,
            });
        }
    }, [auth.currentUser]);

    const params = useParams();
    const navigate = useNavigate()

    const toggleFavorite = (listingId) => {
        if (favorites.includes(listingId)) {
            setFavorites(favorites.filter((id) => id !== listingId)); // Remove from favorites if already favorited
        } else {
            setFavorites([...favorites, listingId]); // Add to favorites if not already favorited
        }
    };

    const handleIndutriteChange = (option) => { // Handler for category change
        setCategory(option?.value); // Set selected category
        console.log("Selected industry:", option); // Check the selected industry
        fetchListings(); // Fetch listings again
    };

    const handleCityChange = (option) => { // Handler for city change
        setSelectedCity(option?.value); // Set selected city
        console.log("Selected city:", option); // Check the selected city
        fetchListings(); // Fetch listings again
    };




    const fetchListings = async () => {
        setLoading(true);

        try {
            const listingsRef = collection(db, 'listings');
            let q = query(listingsRef);

            if (category) {
                q = query(q, where('industria', '==', category));
            }

            if (selectedCity) {
                q = query(q, where('vendndodhja', '==', selectedCity));
            }

            q = query(q, orderBy('timestamp', 'desc'), limit(8)); // Limit the initial fetch to 8 listings

            const querySnap = await getDocs(q);

            const fetchedListings = [];

            querySnap.forEach((doc) => {
                fetchedListings.push({
                    id: doc.id,
                    data: doc.data(),
                });
            });

            setListings(fetchedListings);
            setLastFetchedListing(querySnap.docs[querySnap.docs.length - 1] || null);
        } catch (error) {
            console.error('Error fetching listings:', error);
            toast.error('Listimi nuk mund të bëhet');
        } finally {
            setLoading(false);
        }
    };

    const fetchPremiumListings = async () => {
        setLoading(true);
        try {
            console.log("Fetching premium listings...");
            const listingsRef = collection(db, 'listings');
            let q = query(listingsRef);

            // if (category) {
            //   q = query(q, where('industria', '==', category));
            // }

            q = query(
                q,
                where('premium', '==', true),
                orderBy('timestamp', 'desc'),
                limit(8)
            );

            console.log("Premium listings query:", q); // Log the premium listings query

            const querySnap = await getDocs(q);

            const premiumListings = [];

            querySnap.forEach((doc) => {
                premiumListings.push({
                    id: doc.id,
                    data: doc.data(),
                });
            });

            console.log("Premium listings:", premiumListings); // Log the fetched premium listings

            setPremiumListings(premiumListings);
        } catch (error) {
            console.error('Error fetching premium listings:', error);
            toast.error('Error fetching premium listings');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => { // Effect for initial load and category change
        if (!initialLoad) { // Check if it's the initial load
            setInitialLoad(true); // Set initial load status to true
            return; // Return to avoid re-render
        }

        fetchListings(); // Fetch listings
        fetchPremiumListings();

    }, [category, initialLoad]); // Run the effect when category or initialLoad changes

    useEffect(() => { // Effect for city change
        const isSelectionComplete = selectedCity || category; // Check if both city and category are selected

        if (isSelectionComplete) { // If both selections are complete
            fetchListings(); // Fetch listings
        }

    }, [category, selectedCity]); // Run the effect when category or selectedCity changes

    // useEffect(() => {
    //     // Check authentication state
    //     if (auth.currentUser) {
    //         setFormData({
    //             name: auth.currentUser.displayName || '',
    //             email: auth.currentUser.email || '',
    //         });
    //     }
    //     setAuthLoading(false);
    // }, [auth]);

    const onFetchMoreListings = async () => {
        setLoading(true);

        try {
            console.log("Fetching more listings...");
            console.log("Last fetched listing:", lastFetchedListing);

            const listingsRef = collection(db, 'listings');
            let q = query(listingsRef);

            if (category) {
                q = query(q, where('industria', '==', category));
            }

            if (lastFetchedListing) {
                q = query(q, orderBy('timestamp', 'desc'), startAfter(lastFetchedListing), limit(8)); // Move orderBy before startAfter
            } else {
                // This is in case the last fetched listing is null (which means there are no more listings to fetch)
                // In such cases, you can return or display a message indicating no more listings
                console.log("No last fetched listing found.");
                return;
            }

            const querySnap = await getDocs(q);

            const fetchedListings = [];

            querySnap.forEach((doc) => {
                fetchedListings.push({
                    id: doc.id,
                    data: doc.data(),
                });
            });

            console.log("Fetched listings:", fetchedListings);

            setListings((prevListings) => [...prevListings, ...fetchedListings]);
            setLastFetchedListing(querySnap.docs[querySnap.docs.length - 1]);
        } catch (error) {
            console.error('Error fetching more listings:', error);
            toast.error('Listimi nuk mund të bëhet');
        } finally {
            setLoading(false);
        }
    };

    const onLogout = () => {
        auth.signOut()
        navigate('/')
    }

    const onProfiliPunes = () => {
        navigate('/krijo-profilin')
    }

    const onEditProfiliPunes = () => {
        navigate('/edito-profilin')
    }

    const onSubmit = async () => {
        try {
            if (auth.currentUser.displayName !== name) {
                // Update display name in fb
                await updateProfile(auth.currentUser, {
                    displayName: name,
                })

                // Update in firestore
                const jobseekersRef = doc(db, 'jobseekers', auth.currentUser.uid)
                await updateDoc(jobseekersRef, {
                    name,
                })
            }
        } catch (error) {
            console.log(error)
            toast.error('Detajet e profilit nuk mund të azhurnohen')
        }
    }

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }))
    }

    const onDelete = async (listingId) => {
        if (window.confirm('Are you sure you want to delete?')) {
            await deleteDoc(doc(db, 'listings', listingId))
            const updatedListings = listings.filter(
                (listing) => listing.id !== listingId
            )
            setListings(updatedListings)
            toast.success('Listimi u Fshi me sukses')
        }
    }

    const onEdit = (listingId) => navigate(`/edit-listing/${listingId}`)

    const onPremium = () => navigate(`/checkout/`)

    return (
        <div className='profile'>
            <header className='profileHeader'>
                <p className='pageHeader'>Profili im</p>
                <button type='button' className='logOut' onClick={onLogout}>
                    Dil
                </button>
            </header>

            <main>
                <div className='profileDetailsHeader'>
                    <p className='profileDetailsText'>Detajet Personale</p>
                    <p
                        className='changePersonalDetails'
                        onClick={() => {
                            changeDetails && onSubmit()
                            setChangeDetails((prevState) => !prevState)
                        }}
                    >
                        {changeDetails ? 'u ndryshua' : 'ndrysho emrin dhe emailin'}
                    </p>
                </div>

                <div className='profileCard'>
                    <form>
                        <input
                            type='text'
                            id='name'
                            className={!changeDetails ? 'profileName' : 'profileNameActive'}
                            disabled={!changeDetails}
                            value={name}
                            onChange={onChange}
                        />
                        <input
                            type='text'
                            id='email'
                            className={!changeDetails ? 'profileEmail' : 'profileEmailActive'}
                            disabled={!changeDetails}
                            value={email}
                            onChange={onChange}
                        />
                    </form>

                    <div className='kp'>
                        <button type='button' className='krijoprofilin' onClick={onProfiliPunes}>
                            Krijo Profilin
                        </button>

                        <button type='button' className='krijoprofilin' onClick={onEditProfiliPunes}>
                            Edito Profilin
                        </button>
                    </div>
                </div>



                <div className='category'>
                    <div className='logonori'>
                        {/* <Logonor /> */}
                    </div>
                    <header>
                        <p> Kërko vende pune sipas industrise ose qytetit </p>
                    </header>

                    <form>
                        <div className='searchinput'>
                            <Select
                                placeholder="Zgjedh Industrine"
                                className='formSelect'
                                options={categories}
                                onChange={handleIndutriteChange}
                            />

                            {/* <h3> Zgjedh Qytetin </h3> */}
                            <Select
                                placeholder="Zgjedh Qytetin"
                                className='formSelect'
                                options={city}
                                onChange={handleCityChange}
                            />
                        </div>
                    </form>

                    {/* <Leftside /> */}


                    {loading ? (
                        <Spinner />
                    ) : listings && listings.length > 0 ? (
                        <>

                            <main>

                                <p> Premium </p>
                                <ul className='categoryListings'>
                                    {premiumListings.map(listing => (
                                        <ListingItem
                                            listing={listing.data}
                                            id={listing.id}
                                            key={listing.id}
                                        />
                                    ))}
                                </ul>

                                <p> Tjera </p>
                                <ul className='categoryListings'>
                                    {listings
                                        .filter(listing => !favorites.includes(listing.id)) // Render non-favorite listings after favorite ones
                                        .map(listing => (
                                            <ListingItem
                                                listing={listing.data}
                                                id={listing.id}
                                                key={listing.id}
                                                onFavoriteToggle={() => toggleFavorite(listing.id)}
                                                isFavorite={favorites.includes(listing.id)}
                                            />
                                        ))}
                                </ul>
                            </main>

                            <br />
                            <br />
                            {lastFetchedListing && (
                                <p className='loadMore' onClick={onFetchMoreListings}>
                                    Shfleto më shumë
                                </p>
                            )}
                        </>
                    ) : (
                        <p>Ska listime për {params.categoryName}</p>
                    )}
                </div>
            </main>
        </div>
    )
}

export default JobSeekerProfile