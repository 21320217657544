// Import the functions you need from the SDKs you need
import { initializeApp} from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBCzym0AZkvWycrqTyEYNQSfsgT3iIokNg",
  authDomain: "puna-e96d0.firebaseapp.com",
  projectId: "puna-e96d0",
  storageBucket: "puna-e96d0.appspot.com",
  messagingSenderId: "492584719808",
  appId: "1:492584719808:web:953186f2894518ebaea425",
  measurementId: "G-410185DZNK"
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const db = getFirestore()