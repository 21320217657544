import { useState, useEffect, useRef } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from 'firebase/storage'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase.config'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import Spinner from '../components/Spinner'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


function CreateJobProfile() {
    // eslint-disable-next-line
    const [dataeskadimit, setDataeskadimit] = useState('');
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        lastname: '',
        birthdate: '',
        profesioni: '',
        pervoja: '',
        edukimi: '',
        cerfifikimet: '',
        aftesite: '',
        bio: '',
        jobseekerimage: '',
        premiumjobseeker: false,
    })

    const {
        name,
        lastname,
        birthdate,
        profesioni,
        pervoja,
        edukimi,
        certifikimet,
        aftesite,
        premiumjobseeker,
        bio,
        jobseekerimage,
    } = formData


    // seekers imgUrls 'seekersImgUrls'

    const [category, setCategory] = useState(null);
    const [selectedVendbanimi, setSelectedVendbanimi] = useState(null);


    const kualifikimi = [
        { value: 'Fillor', label: 'Fillor' },
        { value: 'Mesem', label: 'Mesëm' },
        { value: 'Larte', label: 'Lartë' },
        { value: 'Bacelor', label: 'Baçelor' },
        { value: 'Master', label: 'Master' },
        { value: 'Doktorature', label: 'Doktoraturë' },
    ];


    const vendbanimi = [
        { value: 'Prishtine', label: 'Prishtine' },
        { value: 'Prizren', label: 'Prizren' },
        { value: 'Peje', label: 'Peje' },
        { value: 'Ferizaj', label: 'Ferizaj' },
        { value: 'Gjilan', label: 'Gjilan' },
        { value: 'Mitrovice', label: 'Mitrovice' },
        { value: 'Fushe Kosove', label: 'Fushe Kosove' },
        { value: 'Gjakove', label: 'Gjakove' },
        { value: 'Decan', label: 'Decan' },
        { value: 'Lipjan', label: 'Lipjan' },
        { value: 'Podujeve', label: 'Podujeve' },
        { value: 'Kline', label: 'Kline' },
        { value: 'Suhareke', label: 'Suhareke' },
        { value: 'Drenas', label: 'Drenas' },
        { value: 'Vushtri', label: 'Vushtri' },
        { value: 'Skenderaj', label: 'Skenderaj' },
        { value: 'Rahovec', label: 'Rahovec' },
        { value: 'Shtime', label: 'Shtime' },
        { value: 'Viti', label: 'Viti' },
        { value: 'Kacanik', label: 'Kacanik' },
        { value: 'Kastriot', label: 'Kastriot' },
    ];

    const handleBirthDateChange = (date) => {
        setDataeskadimit(date);
    };

    const auth = getAuth()
    const navigate = useNavigate()
    const isMounted = useRef(true)



    const handleEdukimiChange = (option) => {
        setCategory(option?.value);
        setFormData((prevState) => ({
            ...prevState,
            kualifikimi: option?.value, // Update the industria value in the form data
        }));
    };



    const handleVendbanimiChange = (option) => {
        setCategory(option?.value);
        setFormData((prevState) => ({
            ...prevState,
            vendndodhja: option?.value, // Update the industria value in the form data
        }));
    };



    useEffect(() => {
        if (isMounted) {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    setFormData({ ...formData, userRef: user.uid })
                } else {
                    navigate('/sign-in-js')
                }
            })
        }


        return () => {
            isMounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted])

    const onSubmit = async (e) => {
        e.preventDefault()

        setLoading(true)


        if (jobseekerimage.length > 6) {
            setLoading(false)
            toast.error('Maksimumi 6 foto')
            return
        }

        // Store image in firebase
        const storeImage = async (image) => {
            return new Promise((resolve, reject) => {
                const storage = getStorage()
                const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`

                const storageRef = ref(storage, 'jobseekerimage/' + fileName)

                const uploadTask = uploadBytesResumable(storageRef, image)

                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        console.log('Upload is ' + progress + '% done')
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused')
                                break
                            case 'running':
                                console.log('Upload is running')
                                break
                            default:
                                break
                        }
                    },
                    (error) => {
                        reject(error)
                    },
                    () => {
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            resolve(downloadURL)
                        })
                    }
                )
            })
        }

        const seekersImgUrls = await Promise.all(
            [...jobseekerimage].map((image) => storeImage(image))
        ).catch(() => {
            setLoading(false)
            toast.error('Fotot nuk u ngarkuan')
            return
        })

        const formDataCopy = {
            ...formData,
            seekersImgUrls,
            birthdate: birthdate ? birthdate.toLocaleDateString() : '', // Format date as string
            timestamp: serverTimestamp(),
        }
        // console.log(`tipi`,typeof formDataCopy.space, formDataCopy.space);
        // formDataCopy.location = address
        delete formDataCopy.jobseekerimage
        delete formDataCopy.textbody

        const docRef = await addDoc(collection(db, 'listings'), formDataCopy)
        setLoading(false)
        toast.success('Listimi u ruajt')
        navigate(`/category/kat/${docRef.id}`)
    }
    // console.log(typeof formData.space)


    const onMutate = (e) => {
        let boolean = null;

        if (e.target.value === 'true') {
            boolean = true;
        }
        if (e.target.value === 'false') {
            boolean = false;
        }



        // Files
        if (e.target.files) {
            setFormData((prevState) => ({
                ...prevState,
                [e.target.id]: e.target.files ?? boolean ?? (e.target.id === 'space' ? parseFloat(e.target.value) : e.target.value),
            }));
        }

        // Text/Booleans/Numbers
        if (!e.target.files) {
            setFormData((prevState) => ({
                ...prevState,
                [e.target.id]: boolean ?? (e.target.id === 'space' ? parseFloat(e.target.value) : e.target.value),
            }));
        }
    };


    if (loading) {
        return <Spinner />
    }


    if (loading) {
        return <Spinner />
    }

    return (
        <div className='profile'>
            <header>
                <p className='pageHeader'>Krijo Profilin</p>
            </header>

            <main>
                <form onSubmit={onSubmit}>

                    <label className='formLabel'>Emri</label>
                    <input
                        className='formInputName'
                        type='text'
                        id='name'
                        value={name}
                        onChange={onMutate}
                        maxLength='36'
                        minLength='3'
                        required
                    />

                    <label className='formLabel'>Mbiemri</label>
                    <input
                        className='formInputName'
                        type='text'
                        id='lastname'
                        value={lastname}
                        onChange={onMutate}
                        maxLength='36'
                        minLength='3'
                        required
                    />

                    <div>
                        <label className='formLabel'>Vendbanimi</label>
                        <Select
                            className='formSelect'
                            options={vendbanimi}
                            onChange={handleVendbanimiChange}
                            id='vendbanimi'
                            placeholder="Zgjedhe Vendbaimin"
                            required
                        />
                    </div>

                    <label className='formLabel'>Edukimi</label>
                    <Select
                        className='formSelect'
                        options={kualifikimi}
                        onChange={handleEdukimiChange}
                        id='kualifikimi'
                        placeholder="Zgjedh Edukimin"
                        required
                    />

                    <label className='formLabel'>Profesioni</label>
                    <input
                        className='formInputName'
                        type='text'
                        id='profesioni'
                        value={profesioni}
                        onChange={onMutate}
                        maxLength='36'
                        minLength='2'
                        required
                    />

                    <div>
                        <label className='formLabel'>Data e Lindjes</label>
                        <DatePicker
                            className='formInputName'
                            selected={birthdate}
                            onChange={handleBirthDateChange}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Zgjedh daten e lindjes"
                            id='birthdate'
                            required
                        />
                    </div>

                    <label className='formLabel'>Përvoja</label>
                    <textarea
                        className='formInputName'
                        type='text'
                        id='pervoja'
                        value={pervoja}
                        onChange={onMutate}
                        maxLength='3600'
                        minLength='10'
                        rows="15"
                        required
                    />

                    <label className='formLabel'>Certifikimet</label>
                    <textarea
                        className='formInputName'
                        type='text'
                        id='certifikimet'
                        value={certifikimet}
                        onChange={onMutate}
                        maxLength='3600'
                        minLength='10'
                        rows="15"
                        required
                    />

                    <label className='formLabel'>Aftësitë Tjera</label>
                    <textarea
                        className='formInputName'
                        type='text'
                        id='aftesite'
                        value={aftesite}
                        onChange={onMutate}
                        maxLength='3600'
                        minLength='10'
                        rows="15"
                        required
                    />

                    <label className='formLabel'>Prezentim i shkurtër i vetes</label>
                    <textarea
                        className='formInputName'
                        type='text'
                        id='bio'
                        value={bio}
                        onChange={onMutate}
                        maxLength='3600'
                        minLength='1'
                        rows="15"
                        required
                    />


                    {/* </div> */}

                    <label className='formLabel'>Fotot</label>
                    <p className='imagesInfo'>
                        Foto e pare do te vendoset si logo (max 1).
                    </p>
                    <input
                        className='formInputFile'
                        type='file'
                        id='jobseekerimage'
                        onChange={onMutate}
                        max='6'
                        accept='.jpg,.png,.jpeg'
                        multiple

                    />
                    <button type='submit' className='primaryButton createListingButton'>
                        Krijo Profilin
                    </button>
                </form>
            </main>
        </div>
    )
}

export default CreateJobProfile


