
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase.config';
import { useUser } from '../context/UserContext';
import visibilityIcon from '../assets/svg/visibilityIcon.svg';
import Jobimage from '../components/Jobimage';
import Logonor from '../components/Logonor';
import Paysera from '../components/Paysera'
import '../css/paysera.css'


function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const { email, password } = formData;
  const { setUserType } = useUser();
  const navigate = useNavigate();

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      if (userCredential.user) {
        const userDocRef = doc(db, 'users', userCredential.user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          setUserType('user');
          navigate('/profili');
        } else {
          // If user document does not exist in 'users' collection, check 'jobseekers' collection
          const jobSeekerDocRef = doc(db, 'jobseekers', userCredential.user.uid);
          const jobSeekerDoc = await getDoc(jobSeekerDocRef);

          if (jobSeekerDoc.exists()) {
            setUserType('jobseeker');
            navigate('/profili-punekerkuesit');
          } else {
            toast.error('Kredencialet janë gabim');
          }
        }
      }
    } catch (error) {
      toast.error('Kredencialet janë gabim');
    }
  };

  return (
    <>
      <div className='logonori'>
        <Logonor />
      </div>

      <div className='pageContainer'>
        <div className='jobclass'>
          <Jobimage />
        </div>

        <div className='jobclass2'>
          <form onSubmit={onSubmit}>
            <input
              type='email'
              className='emailInput'
              placeholder='Email'
              id='email'
              value={email}
              onChange={onChange}
            />

            <div className='passwordInputDiv'>
              <input
                type={showPassword ? 'text' : 'password'}
                className='passwordInput'
                placeholder='Fjalëkalimi'
                id='password'
                value={password}
                onChange={onChange}
              />

              <img
                src={visibilityIcon}
                alt='show password'
                className='showPassword'
                onClick={() => setShowPassword((prevState) => !prevState)}
              />
            </div>

            <Link to='/forgot-password' className='forgotPasswordLink'>
              Kam harruar fjalekalimin
            </Link>

            <div className=''>
              <button className='registerLink2'>Kyqu</button>
            </div>
          </form>

          <Link to='/regjistrohu' className='registerLink'>
            Krijoni Llogarinë tuaj
          </Link>
        </div>

      </div>

      <div className='linkuprivatesise'>
        <Link to='/privatesia' className='privatesia'>
          Politika e Privatësisë
        </Link>

        <Link to='/rimbursimi' className='privatesia'>
          Politika e Rimbursimit
        </Link>

        <div className='paysera'>
          <Paysera />
        </div>

      </div>

    </>
  );
}

export default SignIn;