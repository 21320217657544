
import { IoMdCheckmark } from "react-icons/io";
import { FaXmark } from "react-icons/fa6";
import { MdAlternateEmail } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";

function Kontakti() {


  return (

    <div className='category'>

<div className='kontaktContainer'>
        <div className='phoneCont'>
          <p className='telefoni'>
          <FiPhoneCall /></p><p className='telefoniText'>+383 44 361 669</p>
        </div>
        <div className='phoneCont'>
          <p className='telefoni'>
          <MdAlternateEmail /></p><p className='telefoniText'>info@konkurse.co</p>
        </div>
      </div>

      <div className='falaspremiumContainer'>
        <div className='falas'>
          <h2>Falas</h2>
          <div className='cmimi'>
            <h3 className='cmimiPremium'>0€</h3>
            <h2 className='cmimiMuaji'>/muaji</h2>
          </div>
          <p>Veçoritë</p>
          <p><IoMdCheckmark /> Shfaqet në platformë</p>
          <p><IoMdCheckmark /> Kohëzgjatja 1 Javë</p>
          <p><FaXmark /> Perkrahje Teknike</p>
          <p><FaXmark /> Vetë-Postim </p>
          <p><FaXmark /> Nuk shfaqet tek pjesa Premium</p>
        </div>

        <div className='bazike'>
          <h2>Bazike</h2>
          <div className='cmimi'>
            <h3 className='cmimiPremium'>25€</h3>
            <h2 className='cmimiMuaji'>/muaji</h2>
          </div>
          <p>Veçoritë</p>
          <p><IoMdCheckmark /> Shfaqet në platformë</p>
          <p><IoMdCheckmark /> Shfaqet në Rrjete Sociale</p>
          <p><IoMdCheckmark /> Kohëzgjatja 3 Javë</p>
          <p><IoMdCheckmark /> Perkrahje Teknike</p>
          <p><FaXmark /> Nuk shfaqet tek pjesa Premium</p>
        </div>

        <div className='premium'>
          <h2>Premium</h2>
          <div className='cmimi'>
            <h3 className='cmimiPremium'>50€</h3>
            <h2 className='cmimiMuaji'>/muaji</h2>
          </div>
          <p>Veçoritë</p>
          <p><IoMdCheckmark /> Shfaqet në platformë</p>
          <p><IoMdCheckmark /> Shfaqet tek pjesa Premium</p>
          <p><IoMdCheckmark /> Shfaqet në Rrjete Sociale</p>
          <p><IoMdCheckmark /> Sponsorizohet në Rrjete Sociale</p>
          <p><IoMdCheckmark /> Kohëzgjatja 1 Muaj</p>
          <p><IoMdCheckmark /> Perkrahje Teknike</p>
          <p><IoMdCheckmark /> Opsioni i postimit nga Stafi i Konkurse</p>
        </div>
      </div>

    </div>
  )
}

export default Kontakti
