import React, { useEffect, useState } from 'react';

const PaymentCallback = () => {
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState('Processing your payment...');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status'); // Assuming 'status' is passed in the URL query params

    if (status) {
      if (status === '1') {
        setStatus('success');
        setMessage('Your payment was successful. Thank you!');
      } else {
        setStatus('failed');
        setMessage('Your payment has failed. Please try again.');
      }
    } else {
      setStatus('error');
      setMessage('Payment status not available.');
    }
  }, []);

  return (
    <div>
      <h1>Payment Callback</h1>
      <p>{message}</p>
    </div>
  );
};

export default PaymentCallback;