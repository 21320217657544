import logoja2 from '../assets/jpg/logonor.png'

function Logonor() {

  return (

    <img
      src={logoja2}
      alt="logoja2"
      className='logoimg'
    />
  )



}

export default Logonor