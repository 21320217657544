import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, doc, getDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase.config';
import ListingItem from '../components/ListingItem';

function UserProfile() {
    const { userId } = useParams();
    const [userData, setUserData] = useState(null);
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userDoc = await getDoc(doc(db, 'users', userId));
                if (userDoc.exists()) {
                    setUserData(userDoc.data());
                    fetchUserListings(userId);
                } else {
                    console.log('User not found');
                    setLoading(false); // Set loading to false if user not found
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setLoading(false); // Set loading to false if error occurs
            }
        };

        fetchUserData();
    }, [userId]);

    const fetchUserListings = async (userId) => {
        try {
            const listingsRef = collection(db, 'listings');
            const q = query(
                listingsRef,
                where('userRef', '==', userId)
            );
            const querySnapshot = await getDocs(q);
            const userlistings = [];
            querySnapshot.forEach((doc) => {
                userlistings.push({
                    id: doc.id,
                    data: doc.data(),
                });
            });
            setListings(userlistings);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user listings:', error);
            setLoading(false); // Set loading to false if error occurs
        }
    };

    if (!userData) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            <h1>User Profile</h1>
            <p>Name: {userData.name}</p>
            <p>Email: {userData.email}</p>
            <h2>Listings:</h2>
            {loading ? <p>Loading listings...</p> : (
                <ul>
                    {listings.map((listing) => (
                        <ListingItem
                            key={listing.id}
                            listing={listing.data}
                            id={listing.id}
                        // If you need onDelete and onEdit functionality, pass appropriate functions here
                        />
                    ))}
                </ul>
            )}
        </div>
    );
}

export default UserProfile;