import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../firebase.config';
import { toast } from 'react-toastify';
import Spinner from '../components/Spinner';
import ListingItem from '../components/ListingItem';
import Select from 'react-select';
import { startAfter, limit } from 'firebase/firestore';
import Logonor from '../components/Logonor'


function Search() {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastFetchedListing, setLastFetchedListing] = useState(null);
  const [initialLoad, setInitialLoad] = useState(false);
  const [favorites, setFavorites] = useState([]); // State to store favorite listings
  const [premiumListings, setPremiumListings] = useState([]);

  // const params = useParams();
  const categories = [
    { value: 'Energji', label: 'Energji' },
    { value: 'Tregti', label: 'Tregti' },
    { value: 'Sherbime', label: 'Shërbime' },
    { value: 'Administrate', label: 'Administrate' },
    { value: 'Telekomunikime', label: 'Telekomunikime' },
    { value: 'Teknologji', label: 'Teknologji' },
    { value: 'Media', label: 'Media' },
    { value: 'Financiare', label: 'Financiare' },
    { value: 'Patundeshmeri', label: 'Patundshmëri' },
    { value: 'Transport', label: 'Transport' },
    { value: 'Ushqimore', label: 'Ushqimore' },
    { value: 'Mjekesi', label: 'Mjekësi' },
    { value: 'Tekstil', label: 'Tekstil' },
    { value: 'Hoteleri', label: 'Hoteleri' },
    { value: 'Edukim', label: 'Edukim' },
  ];


  const city = [
    { value: 'Prishtine', label: 'Prishtine' },
    { value: 'Prizren', label: 'Prizren' },
    { value: 'Peje', label: 'Peje' },
    { value: 'Ferizaj', label: 'Ferizaj' },
    { value: 'Gjilan', label: 'Gjilan' },
    { value: 'Mitrovice', label: 'Mitrovice' },
    { value: 'Fushe Kosove', label: 'Fushe Kosove' },
    { value: 'Gjakove', label: 'Gjakove' },
    { value: 'Decan', label: 'Decan' },
    { value: 'Lipjan', label: 'Lipjan' },
    { value: 'Podujeve', label: 'Podujeve' },
    { value: 'Kline', label: 'Kline' },
    { value: 'Suhareke', label: 'Suhareke' },
    { value: 'Drenas', label: 'Drenas' },
    { value: 'Vushtri', label: 'Vushtri' },
    { value: 'Skenderaj', label: 'Skenderaj' },
    { value: 'Rahovec', label: 'Rahovec' },
    { value: 'Shtime', label: 'Shtime' },
    { value: 'Viti', label: 'Viti' },
    { value: 'Kacanik', label: 'Kacanik' },
    { value: 'Kastriot', label: 'Kastriot' },
    { value: 'Dragash', label: 'Dragash' },
    { value: 'Hani i Elezit', label: 'Hani i Elezit' },
    { value: 'Istog', label: 'Istog' },
    { value: 'Junik', label: 'Junik' },
    { value: 'Kamenice', label: 'Kamenice' },
    { value: 'Kllokot', label: 'Kllokot' },
    { value: 'Leposaviq', label: 'Leposaviq' },
    { value: 'Malisheve', label: 'Malisheve' },
    { value: 'Novoberde', label: 'Novoberde' },
    { value: 'Ranillug', label: 'Ranillug' },
    { value: 'Shtërpce', label: 'Shtërpce' },
    { value: 'Zubin Potok', label: 'Zubin Potok' },
    { value: 'Zvecan', label: 'Zvecan' }
];


  const [category, setCategory] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const params = useParams();


  const toggleFavorite = (listingId) => {
    if (favorites.includes(listingId)) {
      setFavorites(favorites.filter((id) => id !== listingId)); // Remove from favorites if already favorited
    } else {
      setFavorites([...favorites, listingId]); // Add to favorites if not already favorited
    }
  };

  const handleIndutriteChange = (option) => { // Handler for category change
    setCategory(option?.value); // Set selected category
    console.log("Selected industry:", option); // Check the selected industry
    fetchListings(); // Fetch listings again
  };

  const handleCityChange = (option) => { // Handler for city change
    setSelectedCity(option?.value); // Set selected city
    console.log("Selected city:", option); // Check the selected city
    fetchListings(); // Fetch listings again
  };




  const fetchListings = async () => {
    setLoading(true);

    try {
      const listingsRef = collection(db, 'listings');
      let q = query(listingsRef);

      if (category) {
        q = query(q, where('industria', '==', category));
      }

      if (selectedCity) {
        q = query(q, where('vendndodhja', '==', selectedCity));
      }

      q = query(q, orderBy('timestamp', 'desc'), limit(8)); // Limit the initial fetch to 8 listings

      const querySnap = await getDocs(q);

      const fetchedListings = [];

      querySnap.forEach((doc) => {
        fetchedListings.push({
          id: doc.id,
          data: doc.data(),
        });
      });

      setListings(fetchedListings);
      setLastFetchedListing(querySnap.docs[querySnap.docs.length - 1] || null);
    } catch (error) {
      console.error('Error fetching listings:', error);
      toast.error('Listimi nuk mund të bëhet');
    } finally {
      setLoading(false);
    }
  };

  const fetchPremiumListings = async () => {
    setLoading(true);
    try {
      console.log("Fetching premium listings...");
      const listingsRef = collection(db, 'listings');
      let q = query(listingsRef);
  
      // if (category) {
      //   q = query(q, where('industria', '==', category));
      // }
  
      q = query(
        q,
        where('premium', '==', true),
        orderBy('timestamp', 'desc'),
        limit(8)
      );
  
      console.log("Premium listings query:", q); // Log the premium listings query
  
      const querySnap = await getDocs(q);
  
      const premiumListings = [];
  
      querySnap.forEach((doc) => {
        premiumListings.push({
          id: doc.id,
          data: doc.data(),
        });
      });
  
      console.log("Premium listings:", premiumListings); // Log the fetched premium listings
  
      setPremiumListings(premiumListings);
    } catch (error) {
      console.error('Error fetching premium listings:', error);
      toast.error('Error fetching premium listings');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => { // Effect for initial load and category change
    if (!initialLoad) { // Check if it's the initial load
      setInitialLoad(true); // Set initial load status to true
      return; // Return to avoid re-render
    }

    fetchListings(); // Fetch listings
    fetchPremiumListings();

  }, [category, initialLoad]); // Run the effect when category or initialLoad changes

  useEffect(() => { // Effect for city change
    const isSelectionComplete = selectedCity || category; // Check if both city and category are selected

    if (isSelectionComplete) { // If both selections are complete
      fetchListings(); // Fetch listings
    }

  }, [category, selectedCity]); // Run the effect when category or selectedCity changes



  const onFetchMoreListings = async () => {
    setLoading(true);

    try {
      console.log("Fetching more listings...");
      console.log("Last fetched listing:", lastFetchedListing);

      const listingsRef = collection(db, 'listings');
      let q = query(listingsRef);

      if (category) {
        q = query(q, where('industria', '==', category));
      }

      if (lastFetchedListing) {
        q = query(q, orderBy('timestamp', 'desc'), startAfter(lastFetchedListing), limit(8)); // Move orderBy before startAfter
      } else {
        // This is in case the last fetched listing is null (which means there are no more listings to fetch)
        // In such cases, you can return or display a message indicating no more listings
        console.log("No last fetched listing found.");
        return;
      }

      const querySnap = await getDocs(q);

      const fetchedListings = [];

      querySnap.forEach((doc) => {
        fetchedListings.push({
          id: doc.id,
          data: doc.data(),
        });
      });

      console.log("Fetched listings:", fetchedListings);

      setListings((prevListings) => [...prevListings, ...fetchedListings]);
      setLastFetchedListing(querySnap.docs[querySnap.docs.length - 1]);
    } catch (error) {
      console.error('Error fetching more listings:', error);
      toast.error('Listimi nuk mund të bëhet');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='category'>
      <div className='logonori'>
        <Logonor />
      </div>
      <header>
        <p> Kërko vende pune sipas industrise ose qytetit </p>
      </header>

      <form>
        <div className='searchinput'>
          <Select
            placeholder="Zgjedh Industrine"
            className='formSelect'
            options={categories}
            onChange={handleIndutriteChange}
          />

          {/* <h3> Zgjedh Qytetin </h3> */}
          <Select
            placeholder="Zgjedh Qytetin"
            className='formSelect'
            options={city}
            onChange={handleCityChange}
          />
        </div>
      </form>

      {/* <Leftside /> */}


      {loading ? (
        <Spinner />
      ) : listings && listings.length > 0 ? (
        <>

          <main>

            <p> Premium </p>
            <ul className='categoryListings'>
              {premiumListings.map(listing => (
                <ListingItem
                  listing={listing.data}
                  id={listing.id}
                  key={listing.id}
                />
              ))}
            </ul>

            <p> Tjera </p>
            <ul className='categoryListings'>
              {listings
                .filter(listing => !favorites.includes(listing.id)) // Render non-favorite listings after favorite ones
                .map(listing => (
                  <ListingItem
                    listing={listing.data}
                    id={listing.id}
                    key={listing.id}
                    onFavoriteToggle={() => toggleFavorite(listing.id)}
                    isFavorite={favorites.includes(listing.id)}
                  />
                ))}
            </ul>
          </main>

          <br />
          <br />
          {lastFetchedListing && (
            <p className='loadMore' onClick={onFetchMoreListings}>
              Shfleto më shumë
            </p>
          )}
        </>
      ) : (
        <p>Ska listime për {params.categoryName}</p>
      )}
    </div>
  );
}

export default Search