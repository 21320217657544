import React from 'react';
import { Link } from 'react-router-dom';
import Palogo from './Palogo';
import { ReactComponent as Time } from '../assets/svg/time.svg';
import { Timestamp } from 'firebase/firestore'; // Import Firestore Timestamp

function ListingItem({ listing, id, onEdit, onDelete, onPremium, onFavoriteToggle, isFavorite }) {
  // Function to handle Firestore Timestamp
  const formatTimestamp = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
    }
    return new Date(timestamp); // For other date formats
  };

  const currentDate = new Date();
  const dataeskadimitDate = formatTimestamp(listing.dataeskadimit);
  const hasPassed = dataeskadimitDate < currentDate;
  const timeDiff = hasPassed ? 0 : dataeskadimitDate.getTime() - currentDate.getTime();
  const daysLeft = hasPassed ? "Ka Skaduar" : Math.ceil(timeDiff / (1000 * 3600 * 24));

  // If the date has passed, don't render the item
  if (hasPassed) {
    return null;
  }

  return (
    <li className='categoryListing'>
      <Link to={`/category/kat/${id}`} className='categoryListingLink'>
        {listing.imgUrls && listing.imgUrls.length > 0 ? (
          <div className="image-wrapper2" style={{ width: '100px', height: '100px', overflow: 'hidden' }}>
            <img
              src={listing.imgUrls[0]}
              alt={listing.kompania}
              className='cateImg'
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        ) : (
          <Palogo />
        )}
        <div className='categoryListingDetails'>
          <p className='formated-text categoryListingName'>{listing.pozita.toUpperCase()}</p>
          <p className='categoryListingLocation'>{listing.vendndodhja}</p>
          <p className='categoryListingDate'><Time className='time' /> {` ${daysLeft} Ditë`}</p>
          <div className='categoryListingInfoDiv'></div>
        </div>
      </Link>

      <div className='editButtons'>
        {onDelete && (
          <div
            className='removeIcon'
            fill='rgb(231, 76,60)'
            onClick={() => onDelete(listing.id, listing.name)}
          ><p>Fshij</p></div>
        )}

        {onEdit && <div className='editIcon' onClick={() => onEdit(id)} ><p>Edito</p></div>}
        {onPremium && <div className='premiumIcon' onClick={() => onPremium(id)} ><p>Premium</p></div>}
      </div>
    </li>
  );
}

export default ListingItem;