import palogoja from '../assets/jpg/placeholder.jpg'
function Palogo() {
    
    return (

      <img
          src={palogoja}
          alt="palogo"
          className='categoryListingImg'
        />  
    )

    

}

export default Palogo