import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase.config';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Spinner from '../components/Spinner';

function AdminEditListing() {
  const { listingId } = useParams();
  const [loading, setLoading] = useState(true);
  const [listing, setListing] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [formData, setFormData] = useState({
    premium: false, // Default value
    // Add other fields here
  });
  

  // Initialize the navigate object
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchListing();
    }
  }, [currentUser]);

  const fetchListing = async () => {
    try {
      const listingRef = doc(db, 'listings', listingId);
      const listingSnap = await getDoc(listingRef);
      if (listingSnap.exists()) {
        setListing(listingSnap.data());
        setFormData(listingSnap.data());
        setLoading(false);
      } else {
        console.error('Listing does not exist');
        navigate('/admin'); // Redirect to admin page if listing does not exist
      }
    } catch (error) {
      console.error('Error fetching listing:', error);
      navigate('/admin'); // Redirect to admin page if error occurs
    }
  };

  const isAdminEditingAllowed = () => {
    // Add logic to determine if the current user is an admin and can edit the listing
    return currentUser && currentUser.email === 'drilon_p@hotmail.com'; // Replace with your admin email
  };

  const handleInputChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  const handleEdit = async () => {
    // Add logic to handle editing the listing fields
    if (isAdminEditingAllowed()) {
      try {
        // Update only the fields that admin is allowed to edit
        const docRef = doc(db, 'listings', listingId);
        await updateDoc(docRef, {
          premium: formData.premium, // Example: Update the 'premium' field to true
          // Add other fields here
        });
        console.log('Listing updated successfully');
        navigate('/admin');
      } catch (error) {
        console.error('Error updating listing:', error);
      }
    } else {
      console.log('Admin editing not allowed');
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (!isAdminEditingAllowed()) {
    return <p>Access denied. You must be an admin to edit this listing.</p>;
  }


  return (
    <div className="edit-listing-container">
      <h1>Edito Listimet Premium</h1>
      <p>ID e listimit: {listingId}</p>
      <div className="input-container">
        <label htmlFor="premium">Premium:</label>
        <input
          type="checkbox"
          id="premium"
          name="premium"
          checked={formData.premium}
          onChange={handleInputChange}
        />
      </div>
      {/* Add other input fields here */}
      <button className="save-button" onClick={handleEdit}>Ruaj Ndryshimet</button>
      <Link to="/admin" className="back-link">Kthehu tek Admin Dashboard</Link>
    </div>
  );
}

export default AdminEditListing;