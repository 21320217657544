import { useState, useEffect, useRef } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase.config'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import Spinner from '../components/Spinner'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


function CreateListing() {
  // eslint-disable-next-line
  const [dataeskadimit, setDataeskadimit] = useState('');
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    kompania: '',
    industria: '',
    pozita: '',
    nripozitave: 1,
    detyrat: '',
    kualifikimet: '',
    shenime: '',
    images: '',
    premium: false,
  })

  const {
    kompania,
    vendndodhja,
    industria,
    pozita,
    nripozitave,
    detyrat,
    kualifikimet,
    shenime,
    premium,
    images,
  } = formData

  const [category, setCategory] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);


  const industrite = [
    { value: 'Energji', label: 'Energji' },
    { value: 'Tregti', label: 'Tregti' },
    { value: 'Sherbime', label: 'Shërbime' },
    { value: 'Administrate', label: 'Administrate' },
    { value: 'Telekomunikime', label: 'Telekomunikime' },
    { value: 'Teknologji', label: 'Teknologji' },
    { value: 'Media', label: 'Media' },
    { value: 'Financiare', label: 'Financiare' },
    { value: 'Patundeshmeri', label: 'Patundshmëri' },
    { value: 'Transport', label: 'Transport' },
    { value: 'Ushqimore', label: 'Ushqimore' },
    { value: 'Mjekesi', label: 'Mjekësi' },
    { value: 'Tekstil', label: 'Tekstil' },
    { value: 'Hoteleri', label: 'Hoteleri' },
    { value: 'Edukim', label: 'Edukim' },
  ];


  const city = [
    { value: 'Prishtine', label: 'Prishtine' },
    { value: 'Prizren', label: 'Prizren' },
    { value: 'Peje', label: 'Peje' },
    { value: 'Ferizaj', label: 'Ferizaj' },
    { value: 'Gjilan', label: 'Gjilan' },
    { value: 'Mitrovice', label: 'Mitrovice' },
    { value: 'Fushe Kosove', label: 'Fushe Kosove' },
    { value: 'Gjakove', label: 'Gjakove' },
    { value: 'Decan', label: 'Decan' },
    { value: 'Lipjan', label: 'Lipjan' },
    { value: 'Podujeve', label: 'Podujeve' },
    { value: 'Kline', label: 'Kline' },
    { value: 'Suhareke', label: 'Suhareke' },
    { value: 'Drenas', label: 'Drenas' },
    { value: 'Vushtri', label: 'Vushtri' },
    { value: 'Skenderaj', label: 'Skenderaj' },
    { value: 'Rahovec', label: 'Rahovec' },
    { value: 'Shtime', label: 'Shtime' },
    { value: 'Viti', label: 'Viti' },
    { value: 'Kacanik', label: 'Kacanik' },
    { value: 'Kastriot', label: 'Kastriot' },
    { value: 'Dragash', label: 'Dragash' },
    { value: 'Hani i Elezit', label: 'Hani i Elezit' },
    { value: 'Istog', label: 'Istog' },
    { value: 'Junik', label: 'Junik' },
    { value: 'Kamenice', label: 'Kamenice' },
    { value: 'Kllokot', label: 'Kllokot' },
    { value: 'Leposaviq', label: 'Leposaviq' },
    { value: 'Malisheve', label: 'Malisheve' },
    { value: 'Novoberde', label: 'Novoberde' },
    { value: 'Ranillug', label: 'Ranillug' },
    { value: 'Shtërpce', label: 'Shtërpce' },
    { value: 'Zubin Potok', label: 'Zubin Potok' },
    { value: 'Zvecan', label: 'Zvecan' }
];

  const handleDateChange = (date) => {
    setDataeskadimit(date);
  };

  const auth = getAuth()
  const navigate = useNavigate()
  const isMounted = useRef(true)



  const handleIndustriaChange = (option) => {
    setCategory(option?.value);
    setFormData((prevState) => ({
      ...prevState,
      industria: option?.value, // Update the industria value in the form data
    }));
  };



  const handleCityChange = (option) => {
    setCategory(option?.value);
    setFormData((prevState) => ({
      ...prevState,
      vendndodhja: option?.value, // Update the industria value in the form data
    }));
  };



  useEffect(() => {



    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setFormData({ ...formData, userRef: user.uid })
        } else {
          navigate('/kyqu')
        }
      })
    }


    return () => {
      isMounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted])

  const onSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)


    if (images.length > 6) {
      setLoading(false)
      toast.error('Maksimumi 6 foto')
      return
    }

    // Store image in firebase
    const storeImage = async (image) => {
      return new Promise((resolve, reject) => {
        const storage = getStorage()
        const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`

        const storageRef = ref(storage, 'images/' + fileName)

        const uploadTask = uploadBytesResumable(storageRef, image)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log('Upload is ' + progress + '% done')
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
                break
              default:
                break
            }
          },
          (error) => {
            reject(error)
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL)
            })
          }
        )
      })
    }

    const imgUrls = await Promise.all(
      [...images].map((image) => storeImage(image))
    ).catch(() => {
      setLoading(false)
      toast.error('Fotot nuk u ngarkuan')
      return
    })

    const formDataCopy = {
      ...formData,
      imgUrls,
      dataeskadimit: dataeskadimit ? dataeskadimit.toLocaleDateString() : '', // Format date as string
      timestamp: serverTimestamp(),
    }
    // console.log(`tipi`,typeof formDataCopy.space, formDataCopy.space);
    // formDataCopy.location = address
    delete formDataCopy.images
    delete formDataCopy.textbody

    const docRef = await addDoc(collection(db, 'listings'), formDataCopy)
    setLoading(false)
    toast.success('Listimi u ruajt')
    navigate(`/category/kat/${docRef.id}`)
  }
  // console.log(typeof formData.space)


  const onMutate = (e) => {
    let boolean = null;

    if (e.target.value === 'true') {
      boolean = true;
    }
    if (e.target.value === 'false') {
      boolean = false;
    }



    // Files
    if (e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.files ?? boolean ?? (e.target.id === 'space' ? parseFloat(e.target.value) : e.target.value),
      }));
    }

    // Text/Booleans/Numbers
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? (e.target.id === 'space' ? parseFloat(e.target.value) : e.target.value),
      }));
    }
  };


  if (loading) {
    return <Spinner />
  }


  if (loading) {
    return <Spinner />
  }

  return (
    <div className='profile'>
      <header>
        <p className='pageHeader'>Listo pozitën</p>
      </header>

      <main>
        <form onSubmit={onSubmit}>

          <label className='formLabel'>Kompania/Institucioni</label>
          <input
            className='formInputName'
            type='text'
            id='kompania'
            value={kompania}
            onChange={onMutate}
            maxLength='64'
            minLength='3'
            required
          />

          {/* <div className='formRooms flex'> */}
          <div>
            <label className='formLabel'>Qyteti</label>
            <Select
              className='formSelect'
              options={city}
              onChange={handleCityChange}
              id='vendndodhja'
              placeholder="Zgjedhe Qytetin"
              required
            />
          </div>

          <label className='formLabel'>Industria</label>
          <Select
            className='formSelect'
            options={industrite}
            onChange={handleIndustriaChange}
            id='industria'
            placeholder="Zgjedhe Industrine"
            required
          />

          <label className='formLabel'>Pozita</label>
          <input
            className='formInputName'
            type='text'
            id='pozita'
            value={pozita}
            onChange={onMutate}
            maxLength='36'
            minLength='2'
            required
          />

          <div>
            <label className='formLabel'>Data e Skadimit</label>
            <DatePicker
              className='formInputName'
              selected={dataeskadimit}
              onChange={handleDateChange}
              dateFormat="MM/dd/yyyy"
              placeholderText="Zgjedh daten e skadimit"
              id='dataeskadimit'
              required
            />
          </div>

          <label className='formLabel'>Numri i Pozitave</label>
          <input
            className='formInputName'
            type='number'
            id='nripozitave'
            value={nripozitave}
            onChange={onMutate}
            maxLength='99'
            minLength='1'
            required
          />

          <label className='formLabel'>Detyrat</label>
          <textarea
            className='formInputName'
            type='text'
            id='detyrat'
            value={detyrat}
            onChange={onMutate}
            maxLength='3600'
            minLength='10'
            rows="15"
            required
          />

          <label className='formLabel'>Kualifikimet</label>
          <textarea
            className='formInputName'
            type='text'
            id='kualifikimet'
            value={kualifikimet}
            onChange={onMutate}
            maxLength='3600'
            minLength='10'
            rows="15"
            required
          />

          <label className='formLabel'>Shenime</label>
          <textarea
            className='formInputName'
            type='text'
            id='shenime'
            value={shenime}
            onChange={onMutate}
            maxLength='3600'
            minLength='1'
            rows="15"
            required
          />


          {/* </div> */}

          <label className='formLabel'>Fotot</label>
          <p className='imagesInfo'>
            Foto e pare do te vendoset si logo (max 1).
          </p>
          <input
            className='formInputFile'
            type='file'
            id='images'
            onChange={onMutate}
            max='6'
            accept='.jpg,.png,.jpeg'
            multiple

          />
          <button type='submit' className='primaryButton createListingButton'>
            Publiko Konkursin
          </button>
        </form>
      </main>
    </div>
  )
}

export default CreateListing


