function Rimbursimi() {


    return (

        <div className='category'>
            <h1>Politika e Rimbursimit</h1>

            <h2>1. Përmbledhje</h2>
            <p>
                Politika jonë e rimbursimit synon të sigurojë drejtësi dhe transparencë për klientët tanë të vlerësuar që përdorin platformën tonë për të listuar mundësi pune. Ju lutemi lexoni me kujdes këtë politikë për të kuptuar kushtet dhe rregullat nën të cilat mund të ofrohen rimbursime.
            </p>

            <h2>2. Kualifikimi për Rimbursime</h2>
            <p>Rimbursimet mund të konsiderohen në kushtet e mëposhtme:</p>
            <ul>
                <li><strong>Çështje Teknike</strong>: Nëse hasni çështje teknike në platformën tonë që parandalojnë listimin tuaj të punës të postohet ose të aksesohet siç është menduar.</li>
                <li><strong>Shërbimi Nuk Është Ofruar</strong>: Nëse shërbimi i listimit të punës i blerë nuk është ofruar ose aktivizuar siç është përshkruar.</li>
                <li><strong>Gabime në Listim</strong>: Nëse ka pasur një gabim nga ana jonë në listimin e postit tuaj të punës (p.sh., informacion i pasaktë që nuk është dhënë nga ju).</li>
            </ul>

            <h2>3. Situatat Jo-Të Rimbursueshme</h2>
            <p>Rimbursimet nuk do të jepen në rastet e mëposhtme:</p>
            <ul>
                <li><strong>Ndryshim Mendimi</strong>: Nëse ndryshoni mendje pas blerjes së një listimi të punës.</li>
                <li><strong>Informacion i Pasaktë</strong>: Nëse listimi i punës përmban informacion të pasaktë për shkak të gabimeve të bëra nga ju gjatë procesit të dorëzimit.</li>
                <li><strong>Performanca e Listimit të Punës</strong>: Nëse listimi i punës nuk tërheq kandidatë ose rezulton në më pak aplikime sesa pritej.</li>
                <li><strong>Shkelje të Politikave</strong>: Nëse listimi i punës shkel kushtet tona të shërbimit dhe hiqet si rezultat.</li>
            </ul>

            <h2>4. Kërkimi i një Rimbursimi</h2>
            <p>Për të kërkuar një rimbursim, ju lutemi ndiqni këto hapa:</p>
            <ol>
                <li><strong>Kontaktoni Mbështetjen</strong>: Dërgoni një email në ekipin tonë të mbështetjes në <a href="mailto:info@konkurse.co">info@konkurse.co</a> brenda 14 ditëve nga blerja juaj. Përfshini detajet e llogarisë suaj, ID-në e listimit të punës dhe një shpjegim të hollësishëm të çështjes.</li>
                <li><strong>Procesi i Rishikimit</strong>: Ekipi ynë i mbështetjes do të rishikojë kërkesën tuaj brenda 5 ditëve të punës. Mund t'ju kontaktojmë për informacion shtesë ose qartësime nëse është e nevojshme.</li>
                <li><strong>Njoftimi i Vendimit</strong>: Ju do të njoftoheni për vendimin tonë përmes emailit. Nëse aprovohet, rimbursimi do të përpunohet brenda 7-10 ditëve të punës.</li>
            </ol>

            <h2>5. Metoda e Rimbursimit</h2>
            <p>
                Rimbursimet e aprovuara do të kreditojnë përsëri metodën origjinale të pagesës të përdorur në kohën e blerjes. Nëse metoda origjinale e pagesës nuk është më e vlefshme, ju lutemi njoftoni ekipin tonë të mbështetjes për rregullime alternative.
            </p>

            <h2>6. Na Kontaktoni</h2>
            <p>Nëse keni ndonjë pyetje ose shqetësim në lidhje me politikën tonë të rimbursimit, ju lutemi na kontaktoni:</p>
            <ul>
                <li><strong>Email</strong>: <a href="mailto:info@konkurse.co">info@konkurse.co</a></li>
                <li><strong>Telefon</strong>: 044 361 669</li>
            </ul>

            <h2>7. Ndryshimet në Politikë</h2>
            <p>
                Ne rezervojmë të drejtën të modifikojmë ose përditësojmë këtë politikë të rimbursimit në çdo kohë pa njoftim paraprak. Çdo ndryshim do të postohet në këtë faqe dhe data e rishikimit të fundit do të tregojë në krye të politikës.
            </p>
        </div>
    )
}

export default Rimbursimi