import jimagu from '../assets/jpg/jimage.png'

function Jobimage() {

    return (

        <img
            src={jimagu}
            alt="jobimage"
            className='jobimg'
        />
    )
}

export default Jobimage