import '../css/privatesia.css'

function Kontakti() {


    return (

        <div className='category'>


            <h1>Politika e Privatësisë</h1>

            <h2>1. Hyrje</h2>
            <p>Mirësevini në platformën Konkurse. Ne jemi të përkushtuar për të mbrojtur privatësinë tuaj. Kjo Politikë Privatësie shpjegon se si mbledhim, përdorim, ndajmë dhe mbrojmë informacionin tuaj personal kur përdorni platformën tonë.</p>

            <h2>2. Mbledhja e Informacionit</h2>
            <p>Ne mbledhim llojet e mëposhtme të informacionit personal:</p>
            <ul>
                <li>Informacion Kontakti: Emri, adresa e emailit, numri i telefonit.</li>
                <li>Informacion Profesional: Detajet e CV-së, historia e punësimit, arsimi, aftësitë.</li>
            </ul>
            <p>Ne mbledhim informacion direkt nga ju kur regjistroheni, aplikoni për punë ose përdorni shërbimet tona. Ne gjithashtu përdorim cookies dhe teknologji të ngjashme për të mbledhur të dhëna përdorimi.</p>

            <h2>3. Përdorimi i Informacionit</h2>
            <p>Ne përdorim informacionin tuaj për të:</p>
            <ul>
                <li>Siguruar dhe përmirësuar shërbimet tona.</li>
                <li>Përshtatur ju me mundësi punësimi.</li>
                <li>Komunikuar me ju në lidhje me llogarinë tuaj dhe aplikimet për punë.</li>
                <li>Kryer hulumtime dhe analiza për të përmirësuar përvojën e përdoruesve.</li>
            </ul>

            <h2>4. Ndërveprimi i Informacionit</h2>
            <p>Ne mund të ndajmë informacionin tuaj me:</p>
            <ul>
                <li>Punëdhënësit: Për të lehtësuar aplikimet dhe rekrutimin.</li>
                <li>Ofruesit e Shërbimeve: Për të na ndihmuar të operojmë dhe përmirësojmë shërbimet tona.</li>
                <li>Autoritetet Ligjore: Kur kërkohet nga ligji ose për të mbrojtur të drejtat tona.</li>
            </ul>

            <h2>5. Siguria e të Dhënave</h2>
            <p>Ne zbatojmë masa sigurie sipas standardeve të industrisë për të mbrojtur të dhënat tuaja, përfshirë enkriptimin dhe serverët e sigurt. Megjithatë, asnjë metodë transmetimi në internet nuk është 100% e sigurt.</p>

            <h2>6. Të Drejtat e Përdoruesve</h2>
            <p>Ju keni të drejtën të:</p>
            <ul>
                <li>Qaseni në informacionin tuaj personal.</li>
                <li>Kërkoni korrigjimin e informacionit tuaj.</li>
                <li>Kërkoni fshirjen e informacionit tuaj.</li>
                <li>Zgjidhni të mos përfshiheni në disa aktivitete të përpunimit të të dhënave.</li>
            </ul>
            <p>Për të ushtruar këto të drejta, ju lutemi na kontaktoni në <a href="mailto:info@konkurse.co">info@konkurse.co</a>.</p>

            <h2>7. Cookies dhe Gjurmimi</h2>
            <p>Ne përdorim cookies për të përmirësuar përvojën tuaj në platformën tonë. Ju mund të menaxhoni preferencat tuaja për cookies përmes cilësimeve të shfletuesit tuaj.</p>

            <h2>8. Lidhjet e Palëve të Treta</h2>
            <p>Platforma jonë mund të përmbajë lidhje me faqet e internetit të palëve të treta. Ne nuk jemi përgjegjës për praktikat e tyre të privatësisë dhe ju inkurajojmë të rishikoni politikat e tyre të privatësisë.</p>

            <h2>9. Ndryshimet në Politikën e Privatësisë</h2>
            <p>Ne mund të përditësojmë këtë Politikë Privatësie herë pas here. Ne do t'ju njoftojmë për çdo ndryshim duke postuar politikën e re në faqen tonë të internetit. Kjo politikë është përditësuar për herë të fundit më 05.06.2024.</p>

            <h2>10. Informacioni i Kontaktit</h2>
            <p>Nëse keni ndonjë pyetje ose shqetësim rreth Politikës sonë të Privatësisë, ju lutemi na kontaktoni në <a href="mailto:info@konkurse.co">info@konkurse.co</a>.</p>
        </div>
    )
}

export default Kontakti
