import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where,doc, deleteDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase.config';
import ListingItem from '../components/ListingItem';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

function UserListingsPage() {
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [userListings, setUserListings] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]); // New state for users
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => {
      if (unsubscribeAuth) {
        unsubscribeAuth();
      }
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchUserListings();
    }
  }, [currentUser]);

  const fetchUserListings = async () => {
    try {
      const listingsCollection = collection(db, 'listings');
      const q = query(listingsCollection, where('userRef', '==', userId));
      const querySnapshot = await getDocs(q);
      const userListingData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      }));
  
      // Extract unique user IDs associated with the listings
      const uniqueUserIds = new Set(userListingData.map(listing => listing.data.userRef));
  
      // Fetch user data for the unique user IDs
      const usersData = await Promise.all(Array.from(uniqueUserIds).map(async userId => {
        const userDoc = await getDoc(doc(db, 'users', userId));
        return { id: userDoc.id, data: userDoc.data() };
      }));
  
      setUsers(usersData);
      setUserListings(userListingData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user listings:', error);
      setLoading(false);
    }
  };


  const handleEditListing = (listingId) => {
    navigate(`/admin-edit-listing/${listingId}`);
  };

  const handlePremiumListing = (listingId) => {
    navigate(`/paysera-checkout/`);
  };

  const onDeleteListing = async (listingId) => {
    if (window.confirm('Are you sure you want to delete this listing?')) {
      try {
        await deleteDoc(doc(db, 'listings', listingId));
        setUserListings(prevListings =>
          prevListings.filter(listing => listing.id !== listingId)
        );
        console.log('Listing deleted successfully');
      } catch (error) {
        console.error('Error deleting listing:', error);
      }
    }
  };

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredListings = userListings.filter(listing =>
    listing.data.pozita.toLowerCase().includes(searchTerm.toLowerCase())
  );


  if (!currentUser) {
    return <p>Loading...</p>;
  }

  return (
    <div className='user-listings-page'>
      <Link to="/admin" className="admin-link">Kthehu tek Admin Dashboard</Link>
      <h1>Listimet e Përdoruesit</h1>
      <p>ID e përdoruesit: {userId}</p>
      <form className="search-user-form">
        <input
          type="text"
          className="search-input"
          placeholder="Kërko Listimet..."
          value={searchTerm}
          onChange={handleSearchTerm}
        />
      </form>

      {/* Display users */}
      <div>
        <h2>Lista e Përdoruesve</h2>
        <ul>
          {users.map(user => (
            <div key={user.id}>
              <p>Name: {user.data.name}</p>
              <p>Email: {user.data.email}</p>
            </div>
          ))}
        </ul>
      </div>


      <div className='listings-container'>
        {!loading ? (
          filteredListings.map((listing) => (
            <ListingItem
              key={listing.id}
              listing={listing.data}
              id={listing.id}
              onDelete={() => onDeleteListing(listing.id)}
              onEdit={() => handleEditListing(listing.id)}
              onPremium={() => handlePremiumListing()}
            />
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}

export default UserListingsPage;