// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/Navbar';
import PrivateRoute from './components/PrivateRoute';
import Kontakti from './pages/Kontakti';
import Category from './pages/Category';
import Profile from './pages/Profile';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import CreateListing from './pages/CreateListing';
import EditListing from './pages/EditListing';
import Listing from './pages/Listing';
import Search from './pages/Search';
import Pergjegjesia from './pages/Pergjegjesia';
import AdminPage from './pages/AdminPage';
import UserProfile from './pages/UserProfile';
import UserListingsPage from './pages/UserListingsPage';
import AdminEditListing from './pages/AdminEditListing';
import PayseraCheckout from './pages/PayseraCheckout.jsx';
import CreateJobProfile from './pages/CreateJobProfile.jsx';
import EditJobProfile from './pages/EdittJobProfile.jsx';
import JobSeekerProfile from './pages/JobSeekerProfile.jsx';
import Privatesia from './pages/Privatesia.jsx';
import Rimbursimi from './pages/Rimbursimi.jsx';
import { useUser, UserProvider } from './context/UserContext.js';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentCancel from './pages/PaymentCancel';
import PaymentCallback from './pages/PaymentCallback';

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Search />} />
          <Route path="/kontakti" element={<Kontakti />} />
          <Route path="/privatesia" element={<Privatesia />} />
          <Route path="/rimbursimi" element={<Rimbursimi />} />
          <Route path="/kerko-pune" element={<Search />} />
          <Route path="/category/:categoryName" element={<Category />} />
          <Route path="/user-profile/:userId" element={<UserProfile />} />
          <Route path="/kyqu" element={<SignIn />} />
          <Route path="/regjistrohu" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/listo-prona" element={<CreateListing />} />
          <Route path="/krijo-profilin" element={<CreateJobProfile />} />

          <Route path="/profili" element={
            <PrivateRoute element={<Profile />} allowedUserType="user" />
          } />
          <Route path="/profili-punekerkuesit" element={
            <PrivateRoute element={<JobSeekerProfile />} allowedUserType="jobseeker" />
          } />

          <Route path="/edito-profilin" element={<EditJobProfile />} />
          <Route path="/edit-listing/:listingId" element={<EditListing />} />
          <Route path="/category/:categoryName/:listingId" element={<Listing />} />
          <Route path="/pergjegjesia" element={<Pergjegjesia />} />

          <Route path="/admin" element={
            <PrivateRoute element={<AdminPage />} allowedUserType="user" />
          } />

          <Route path="/payment-success" element={
            <PrivateRoute element={<PaymentSuccess />} allowedUserType="user" />
          } />
          <Route path="/payment-cancel" element={
            <PrivateRoute element={<PaymentCancel />} allowedUserType="user" />
          } />
          <Route path="/payment-callback" element={
            <PrivateRoute element={<PaymentCallback />} allowedUserType="user" />
          } />

          <Route path="/checkout" element={
            <PrivateRoute element={<PayseraCheckout />} allowedUserType="user" />
          } />

          <Route path="/admin-edit-listing/:listingId" element={
            <PrivateRoute element={<AdminEditListing />} allowedUserType="user" />
          } />

          
          <Route path="/user-listings/:userId" element={<UserListingsPage />} />
        </Routes>
        <Navbar />
        <ToastContainer />
      </Router>
    </UserProvider>
  );
}

export default App;