import { useState, useEffect, useRef } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'
import { doc, updateDoc, getDoc, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase.config'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import Spinner from '../components/Spinner'
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

function EditListing() {
  const [dataeskadimit, setDataeskadimit] = useState(null);
  const [loading, setLoading] = useState(false)
  const [listing, setListing] = useState(null);  
  const [formData, setFormData] = useState({
    kompania: '',
    industria: '',
    pozita: '',
    nripozitave: 1,
    detyrat: '',
    kualifikimet: '',
    shenime: '',
    images: null,
    premium: false,
  })

  const {
    kompania,
    vendndodhja,
    industria,
    pozita,
    nripozitave,
    detyrat,
    kualifikimet,
    shenime,
    images,
  } = formData

  const [category, setCategory] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const industrite = [
    { value: 'Energji', label: 'Energji' },
    { value: 'Tregti', label: 'Tregti' },
    { value: 'Sherbime', label: 'Shërbime' },
    { value: 'Administrate', label: 'Administrate' },
    { value: 'Telekomunikime', label: 'Telekomunikime' },
    { value: 'Teknologji', label: 'Teknologji' },
    { value: 'Media', label: 'Media' },
    { value: 'Financiare', label: 'Financiare' },
    { value: 'Patundeshmeri', label: 'Patundshmëri' },
    { value: 'Transport', label: 'Transport' },
    { value: 'Ushqimore', label: 'Ushqimore' },
    { value: 'Mjekesi', label: 'Mjekësi' },
    { value: 'Tekstil', label: 'Tekstil' },
    { value: 'Hoteleri', label: 'Hoteleri' },
    { value: 'Edukim', label: 'Edukim' },
  ];

  const city = [
    { value: 'Prishtine', label: 'Prishtine' },
    { value: 'Prizren', label: 'Prizren' },
    { value: 'Peje', label: 'Peje' },
    { value: 'Ferizaj', label: 'Ferizaj' },
    { value: 'Gjilan', label: 'Gjilan' },
    { value: 'Mitrovice', label: 'Mitrovice' },
    { value: 'Fushe Kosove', label: 'Fushe Kosove' },
    { value: 'Gjakove', label: 'Gjakove' },
    { value: 'Decan', label: 'Decan' },
    { value: 'Lipjan', label: 'Lipjan' },
    { value: 'Podujeve', label: 'Podujeve' },
    { value: 'Kline', label: 'Kline' },
    { value: 'Suhareke', label: 'Suhareke' },
    { value: 'Drenas', label: 'Drenas' },
    { value: 'Vushtri', label: 'Vushtri' },
    { value: 'Skenderaj', label: 'Skenderaj' },
    { value: 'Rahovec', label: 'Rahovec' },
    { value: 'Shtime', label: 'Shtime' },
    { value: 'Viti', label: 'Viti' },
    { value: 'Kacanik', label: 'Kacanik' },
    { value: 'Kastriot', label: 'Kastriot' },
    { value: 'Dragash', label: 'Dragash' },
    { value: 'Hani i Elezit', label: 'Hani i Elezit' },
    { value: 'Istog', label: 'Istog' },
    { value: 'Junik', label: 'Junik' },
    { value: 'Kamenice', label: 'Kamenice' },
    { value: 'Kllokot', label: 'Kllokot' },
    { value: 'Leposaviq', label: 'Leposaviq' },
    { value: 'Malisheve', label: 'Malisheve' },
    { value: 'Novoberde', label: 'Novoberde' },
    { value: 'Ranillug', label: 'Ranillug' },
    { value: 'Shtërpce', label: 'Shtërpce' },
    { value: 'Zubin Potok', label: 'Zubin Potok' },
    { value: 'Zvecan', label: 'Zvecan' }
  ];

  const auth = getAuth()
  const navigate = useNavigate()

  const handleIndustriaChange = (option) => {
    setCategory(option?.value);
    setFormData((prevState) => ({
      ...prevState,
      industria: option?.value,
    }));
  };

  const handleDateChange = (date) => {
    setDataeskadimit(date);
    setFormData((prevState) => ({
      ...prevState,
      dataeskadimit: date,
    }));
  };

  const params = useParams()
  const isMounted = useRef(true)

  const handleImageChange = (e) => {
    const fileList = e.target.files;
    setFormData((prevState) => ({
      ...prevState,
      images: fileList,
    }));
  };

  const handleCityChange = (option) => {
    setSelectedCity(option); 
    setFormData((prevState) => ({
      ...prevState,
      vendndodhja: option?.value,
    }));
  };

  // Redirect if listing is not user's
  useEffect(() => {
    if (listing && listing.userRef !== auth.currentUser.uid) {
      toast.error('You cannot edit that listing')
      navigate('/')
    }
  }, [listing, auth.currentUser.uid, navigate])

  // Fetch listing to edit
  useEffect(() => {
    const fetchListing = async () => {
      const docRef = doc(db, 'listings', params.listingId);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const data = docSnap.data();
        const expiryDate = data.dataeskadimit ? data.dataeskadimit.toDate() : null;
        
        setFormData({
          ...data,
          dataeskadimit: expiryDate,  
        });
        setListing(data);  
      } else {
        navigate('/');
        toast.error('Listing does not exist');
      }
    };
  
    fetchListing();
  }, [params.listingId, navigate]);

  useEffect(() => {
    console.log('Fetched dataeskadimit:', dataeskadimit);
  }, [dataeskadimit]);

  // Sets userRef to logged in user
  useEffect(() => {
    if (isMounted.current) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setFormData({ ...formData, userRef: user.uid })
        } else {
          navigate('/kyqu')
        }
      })
    }

    return () => {
      isMounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, auth, navigate])

  const onSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)

    // Handle upload of images
    const storeImage = (image) => {
      return new Promise((resolve, reject) => {
        const storage = getStorage()
        const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`
        const storageRef = ref(storage, 'images/' + fileName)
        const uploadTask = uploadBytesResumable(storageRef, image)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log('Upload is ' + progress + '% done')
          },
          (error) => {
            console.log(error)
            reject(error)
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL)
            })
          }
        )
      })
    }

    // Handle upload of images
    const uploadedImageUrls = await Promise.all(
      Array.from(images).map((image) => storeImage(image))
    );

    // Create listing object
    const updatedListing = {
      ...formData,
      images: uploadedImageUrls, // Use uploaded image URLs
      dataeskadimit: dataeskadimit ? dataeskadimit : null,  // Use user-selected date
    };

    // Update listing in Firestore
    const docRef = doc(db, 'listings', params.listingId);
    await updateDoc(docRef, updatedListing);

    setLoading(false);
    toast.success('Listing updated successfully');
    navigate(`/listing/${params.listingId}`);
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <div className='profile'>
      <header>
        <p className='pageHeader'>Edit Listing</p>
      </header>
      <main>
        <form onSubmit={onSubmit}>
          <label className='formLabel'>Kompania</label>
          <input
            className='formInputName'
            type='text'
            id='kompania'
            value={kompania}
            onChange={(e) => setFormData({ ...formData, kompania: e.target.value })}
            required
          />

          <label className='formLabel'>Industria</label>
          <Select
            className='formInputName'
            options={industrite}
            value={industrite.find(ind => ind.value === industria)}
            onChange={handleIndustriaChange}
            placeholder='Select Industria'
          />

          <label className='formLabel'>Vendndodhja</label>
          <Select
            className='formInputName'
            options={city}
            value={city.find(ct => ct.value === vendndodhja)}
            onChange={handleCityChange}
            placeholder='Select City'
          />

          <label className='formLabel'>Pozita</label>
          <input
            className='formInputName'
            type='text'
            id='pozita'
            value={pozita}
            onChange={(e) => setFormData({ ...formData, pozita: e.target.value })}
            required
          />

          <label className='formLabel'>Data e Skadimit</label>
          <DatePicker
            className='formInputName'
            selected={dataeskadimit}
            onChange={handleDateChange}
            dateFormat="MM/dd/yyyy"
            placeholderText="Zgjedh daten e skadimit"
            id='dataeskadimit'
            required
          />

          <label className='formLabel'>Numri i Pozitave</label>
          <input
            className='formInputName'
            type='number'
            id='nripozitave'
            value={nripozitave}
            onChange={(e) => setFormData({ ...formData, nripozitave: e.target.value })}
            minLength='1'
            maxLength='99'
            required
          />

          <label className='formLabel'>Detyrat</label>
          <textarea
            className='formInputName'
            id='detyrat'
            value={detyrat}
            onChange={(e) => setFormData({ ...formData, detyrat: e.target.value })}
            rows="15"
            minLength='10'
            maxLength='3600'
            required
          />

          <label className='formLabel'>Kualifikimet</label>
          <textarea
            className='formInputName'
            id='kualifikimet'
            value={kualifikimet}
            onChange={(e) => setFormData({ ...formData, kualifikimet: e.target.value })}
            rows="15"
            minLength='10'
            maxLength='3600'
            required
          />

          <label className='formLabel'>Shenime</label>
          <textarea
            className='formInputName'
            id='shenime'
            value={shenime}
            onChange={(e) => setFormData({ ...formData, shenime: e.target.value })}
            rows="15"
            minLength='1'
            maxLength='3600'
            required
          />

          <label className='formLabel'>Fotot</label>
          <p className='imagesInfo'>
            Foto e pare do te vendoset si logo (max 1).
          </p>
          <input
            className='formInputFile'
            type='file'
            id='images'
            onChange={handleImageChange}
            accept='.jpg,.png,.jpeg'
            multiple
            required
          />
          
          <button type='submit' className='primaryButton createListingButton'>
            Publiko Konkursin
          </button>
        </form>
      </main>
    </div>
  )
}

export default EditListing