import React, { useState } from 'react';
import crypto from 'crypto-browserify';

const PayseraCheckout = () => {
  const [selectedOption, setSelectedOption] = useState('basic');
  const projectId = process.env.REACT_APP_PAYSA_PROJECT_ID;
  const signPassword = process.env.REACT_APP_PAYSA_SIGN_PASSWORD;
  const orderId = process.env.REACT_APP_PAYSA_ORDER_ID;
  const acceptUrl = process.env.REACT_APP_PAYSA_ACCEPT_URL;
  const cancelUrl = process.env.REACT_APP_PAYSA_CANCEL_URL;
  const callbackUrl = process.env.REACT_APP_PAYSA_CALLBACK_URL;

  // Function to calculate payment amount based on selected option
  const getPaymentAmount = () => {
    return selectedOption === 'basic' ? 2500 : 5000; // Amount in cents (2500 = 25.00 EUR, 5000 = 50.00 EUR)
  };

  // Function to generate signed data for Paysera request
  const generateSign = (data, projectPassword) => {
    const sortedKeys = Object.keys(data).sort();
    const sortedData = {};
    sortedKeys.forEach(key => {
      sortedData[key] = data[key];
    });

    const dataString = new URLSearchParams(sortedData).toString();
    const base64Data = Buffer.from(dataString).toString('base64');
    const urlSafeBase64Data = base64Data.replace(/\+/g, '-').replace(/\//g, '_');
    const sign = crypto.createHash('md5').update(urlSafeBase64Data + projectPassword).digest('hex');

    return { data: urlSafeBase64Data, sign };
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const amount = getPaymentAmount();
    const currency = 'EUR';
    const version = '1.6';

    const data = {
      projectid: projectId,
      orderid: orderId,
      accepturl: acceptUrl,
      cancelurl: cancelUrl,
      callbackurl: callbackUrl,
      amount,
      currency,
      version,
    };

    if (!projectId || !signPassword || !orderId || !acceptUrl || !cancelUrl || !callbackUrl) {
      console.error("Missing required parameters");
      alert("Some required parameters are missing. Please contact support.");
      return;
    }

    const { data: requestData, sign } = generateSign(data, signPassword);

    // Create a form dynamically and submit it to Paysera
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = 'https://www.paysera.com/pay/';

    const dataInput = document.createElement('input');
    dataInput.type = 'hidden';
    dataInput.name = 'data';
    dataInput.value = requestData;
    form.appendChild(dataInput);

    const signInput = document.createElement('input');
    signInput.type = 'hidden';
    signInput.name = 'sign';
    signInput.value = sign;
    form.appendChild(signInput);

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <div>
      <div className="payment-container">
        <h1>Choose Package</h1>
        <form onSubmit={handleSubmit}>
          <div className="payment-option">
            <label>
              <input
                type="radio"
                name="paymentOption"
                value="basic"
                checked={selectedOption === 'basic'}
                onChange={() => setSelectedOption('basic')}
              />
              <span>Basic - 25 EUR</span>
            </label>
          </div>
          <div className="payment-option">
            <label>
              <input
                type="radio"
                name="paymentOption"
                value="premium"
                checked={selectedOption === 'premium'}
                onChange={() => setSelectedOption('premium')}
              />
              <span>Premium - 50 EUR</span>
            </label>
          </div>
          <button type="submit" className="pay-button">Pay with Paysera</button>
        </form>
      </div>
    </div>
  );
};

export default PayseraCheckout;