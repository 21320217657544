import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'swiper/swiper-bundle.css';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase.config';
import Spinner from '../components/Spinner';
import shareIcon from '../assets/svg/shareIcon.svg';

// Use the helper function for converting timestamp
const convertToDate = (timestamp) => {
  if (timestamp && timestamp.toDate) {
    return timestamp.toDate();
  }
  if (timestamp instanceof Date) {
    return timestamp;
  }
  if (typeof timestamp === 'string' || typeof timestamp === 'number') {
    return new Date(timestamp);
  }
  return null;
};

const formatTimestamp = (timestamp) => {
  if (!timestamp) return 'No date available';

  const date = convertToDate(timestamp);

  if (date) {
    return date.toLocaleDateString(); // Adjust format as needed
  }

  console.error('Unexpected timestamp format:', timestamp);
  return 'Invalid date';
};

function Listing() {
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [shareLinkCopied, setShareLinkCopied] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const docRef = doc(db, 'listings', params.listingId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          console.log('Firestore data:', data);

          // Convert Firestore timestamp if necessary
          if (data.dataeskadimit) {
            data.dataeskadimit = convertToDate(data.dataeskadimit);
          }

          setListing(data);
          setLoading(false);
        } else {
          navigate('/');
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching listing:', error);
        setLoading(false);
      }
    };

    fetchListing();
  }, [navigate, params.listingId]);

  if (loading) {
    return <Spinner />;
  }

  if (!listing) {
    return <p>Listing not found</p>;
  }

  return (
    <main className='listingbackground'>
      <Helmet>
        <title>{listing.title || 'Listing'}</title>
      </Helmet>

      <div
        className='shareIconDiv'
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          setShareLinkCopied(true);
          setTimeout(() => {
            setShareLinkCopied(false);
          }, 2000);
        }}
      >
        <img src={shareIcon} alt='Share Icon' />
      </div>

      {shareLinkCopied && <p className='linkCopied'>Linku u kopjua</p>}

      <div className='listingDetails'>
        <div className='image-container'>
          {listing.imgUrls && Array.isArray(listing.imgUrls) ? (
            listing.imgUrls.map((url, index) => (
              <div key={index} className='swiperSlideDiv'>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <div
                    className="image-wrapper"
                    style={{
                      width: '200px',
                      height: '200px',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={url}
                      alt={`Image ${index + 1}`}
                      style={{
                        width: '100%',
                        height: 'auto',
                      }}
                    />
                  </div>
                </a>
              </div>
            ))
          ) : (
            <div>No media available</div>
          )}
        </div>

        {/* <p className='listingName'>
          {listing.kompania || 'No company name'}
        </p> */}

        <div className='toplisting'>
          <div className='listing1'>
            <p className='positionicon'>{listing.kompania || 'No company'}</p>
            <h2 className='mainpozita'>{listing.pozita || 'No position'}</h2>
          </div>
          <div className='listing1'>
            <p>Numri i pozitave: {listing.nripozitave || 'N/A'}</p>
            <p className='positionicon'>Lokacioni: {listing.vendndodhja || 'N/A'}</p>
            <p className='dataeskadimit'>Data e Fundit: {formatTimestamp(listing.dataeskadimit)}</p>
          </div>
        </div>

        <h4 className='maindetyrat'>Detyrat dhe Përgjegjësite</h4>
        <p className='formatted-text'>{listing.detyrat || 'No details available'}</p>
        <h4 className='maindetyrat'>Kualifikimet e kërkuara</h4>
        <p className='formatted-text'>{listing.kualifikimet || 'No qualifications available'}</p>
        <h4 className='maindetyrat'>Shënime</h4>
        <p className='formatted-text'>{listing.shenime || 'No notes available'}</p>
        <div className='padbot'></div>
      </div>
    </main>
  );
}

export default Listing;